import React, { useMemo, useState } from 'react'
import { format } from 'date-fns'
import { Box, Text, Group, Modal, TextInput, Textarea } from '@mantine/core'
import { Card, Table, Button, TextViewMore } from 'components'
import { Link } from 'iconsax-react';
import formatBalance from 'helpers/format-balance'

const Links = ({ user }) => {
  const [opened, setOpened] = useState(false);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const data = useMemo(() => [
    {
      date: new Date(),
      amount: 110,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      status: 'paid'
    },
    {
      date: new Date(),
      amount: 110,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      status: 'paid'
    },
    {
      date: new Date(),
      amount: 110,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      status: 'paid'
    },
  ], [])

  const columns = useMemo(() => [
    {
      Header: 'Date & Time',
      accessor: 'date',
      Cell: ({ value }) => {
        return (
          <Box sx={{ width: 100 }}>
            <Text>{format(value, 'dd MMM yyyy')}</Text>
            <Text size="sm" weight={400}>
              {format(value, 'hh:mm aa xxxxx')}
            </Text>
          </Box>
        )
      }
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => (
        <Text weight={700}>
          {formatBalance(value, user.primaryCurrency)}
        </Text>
      )
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value }) => value
        ? <TextViewMore text={value} maxLength={100} sx={{ width: 300 }} />
        : null
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <Text transform="uppercase" weight={700}>
          {value}
        </Text>
      )
    },
  ], [])

  const handleCancel = () => {
    setOpened(false)
    setAmount('')
    setDescription('')
  }

  const handleCreate = () => {
    setOpened(false)
    setAmount('')
    setDescription('')
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title='Create New Link'
      >
        <TextInput
          size="md"
          label='Label Amount'
          placeholder='Enter Amount'
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <Textarea
          mt='md'
          size="md"
          placeholder='Description'
          label='Enter Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          minRows={5}
          maxRows={5}
          maxlength={500}
        />
        <Text mt='xs' align='right' size='sm'>{description.length}/500</Text>
        <Box mt='lg'>
          <Group>
            <Button variant='default' radius='max' size='lg' onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              radius='max'
              size='lg'
              onClick={handleCreate}
              disabled={!amount || !description}
              sx={{ flex: 1 }}
            >
              Create
            </Button>
          </Group>
        </Box>
      </Modal>
      <Card title={
        <Group position='right'>
          <Button
            radius='max'
            size='lg'
            leftIcon={<Link />}
            onClick={() => setOpened(true)}
          >
            Create New Link
          </Button>
        </Group>
      }>
        <Box mt='sm'>
          <Table
            data={data}
            columns={columns}
            striped
            height={588}
          />
        </Box>
      </Card>
    </>
  )
}

export default Links
