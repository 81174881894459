import React, { useState, useEffect, useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { createStyles, Box, Text } from '@mantine/core'
import { Table } from 'components'
import { getAmount } from 'helpers/transactions-table-helpers'
import axios from 'axios'

const ActivityTable = ({ user }) => {
  const { classes } = useStyles()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = useMemo(() => [
    {
      Header: 'Date & Time',
      accessor: 'date',
      Cell: ({ value }) => {
        const parsedDate = parseISO(value)
        return (
          <Box sx={{ width: 120 }}>
            <Text>{format(parsedDate, 'dd MMM yyyy')}</Text>
            <Text size="sm" weight={400}>
              {format(parsedDate, 'hh:mm aa xxxxx')}
            </Text>
          </Box>
        )
      }
    },
    {
      Header: 'Transaction Type',
      accessor: 'type',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => (
        <Text
          weight={700}
          className={
            value.status === 'completed' &&
              value.entityIdentifier === user.username
              ? classes.addedAmount
              : null
          }
        >
          {value.amount}
        </Text>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <Text className={classes[value]} transform="uppercase" weight={700}>
          {value}
        </Text>
      )
    },
  ], [])

  const data = useMemo(() => {
    return tableData.reduce((prev, curr) => {
      const row = {
        date: curr.transactionDate,
        type: curr.transactionType,
        amount: {
          amount: getAmount(curr, user.username, user.primaryCurrency),
          status: curr.status,
          entityIdentifier: curr.recipientName
        },
        status: curr.status
      }
      return [...prev, row]
    }, [])
  }, [tableData])

  useEffect(() => {
    fetchActivity()
    return () => {
      setTableData([])
    }
  }, [])

  const fetchActivity = async () => {
    setLoading(true)
    try {
      const url = `/api/v0/transactions?page=1&activeProfileUsername=${user?.username}`
      const { data } = await axios.get(url)
      setTableData(data.results)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  return (
    <Table
      data={data}
      columns={columns}
      striped
      height={588}
      loading={loading}
      pagination={false}
    />
  )
}

export default ActivityTable

const useStyles = createStyles((theme) => ({
  addedAmount: {
    color: theme.other.success
  },
  completed: {
    color: theme.other.success
  },
  issued: {
    color: theme.other.warning
  },
  processing: {
    color: theme.other.warning
  },
  failed: {
    color: theme.other.error
  },
  refunded: {
    color: theme.other.error
  },
  cancelled: {
    color: theme.other.error
  }
}))
