import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import {
  useMantineTheme,
  Grid,
  Group,
  Box,
  Text,
  Title,
  ActionIcon,
  Center,
  Anchor
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { EyeSlash, Eye, Link, Send2, Bank, People } from 'iconsax-react'
import { QRCodeCanvas } from 'qrcode.react'
import { Card, Button } from 'components'
import formatBalance from 'helpers/format-balance'
import ActivityTable from './ActivityTable'

const Balance = ({ user }) => {
  const theme = useMantineTheme()
  const [hideBalance, setHideBalance] = useState(false)

  useEffect(() => {
    const hideBalance = localStorage.getItem('hideBalance');
    const value = JSON.parse(hideBalance);
    setHideBalance(value)
  }, [])

  const handleHideBalance = () => {
    const hideBalance = localStorage.getItem('hideBalance');
    const value = JSON.parse(hideBalance);
    localStorage.setItem('hideBalance', !value);
    setHideBalance(!value)
  }

  let accountType = 'Personal'
  if (user.businessMetadata.isBusiness) accountType = 'Business'
  if (user.businessMetadata.isRemittancePartner) accountType = 'Partner'
  if (user.businessMetadata.isRemittancePartnerAgent) accountType = 'Agent'

  const availableBalance = formatBalance(user?.balances[user?.primaryCurrency], user?.primaryCurrency)
  const spacing = theme.spacing.lg

  return (
    <Card
      title={
        <Box>
          <Text size='xl' weight={700}>{`${accountType} Account`}</Text>
          <Text size="md">Powered by Netbank.ph</Text>
        </Box>
      }
      sx={{ backgroundColor: theme.other[theme.colorScheme].lightBgColor, position: 'relative' }}
    >
      <Box sx={{ position: 'absolute', bottom: spacing }}>
        {hideBalance
          ? <Title order={6}>********</Title>
          : <Title order={6} sx={{ color: theme.other[theme.colorScheme].success }}>{availableBalance}</Title>
        }
      </Box>
      <ActionIcon
        variant="transparent"
        onClick={handleHideBalance}
        sx={{ position: 'absolute', bottom: spacing, right: spacing }}
      >
        {hideBalance ? <Eye /> : <EyeSlash />}
      </ActionIcon>
    </Card>
  )
}

const Actions = () => {
  const theme = useMantineTheme()

  const iconColor = theme.other[theme.colorScheme].text

  const actionItems = [
    {
      name: "Deposit / Cash-In", icon: (
        <Center sx={{ width: 28, height: 28, borderRadius: theme.radius.xs, backgroundColor: theme.colors.brand[6] }}>
          <IconPlus size={20} color={theme.other[theme.colorScheme].backgroundColor} />
        </Center >
      )
    },
    { name: "New Link", icon: <Link color={iconColor} /> },
    { name: "Send", icon: <Send2 color={iconColor} /> },
    { name: "Bank Transfer", icon: <Bank color={iconColor} /> },
    { name: "Manage Users", icon: <People color={iconColor} /> },
  ]

  return (
    <Card title="Actions">
      <Group grow align="flex-start">
        {actionItems.map(a => (
          <Button
            key={a.name}
            variant="transparent"
            color={theme.other[theme.colorScheme].text}
            topIcon={a.icon}
            sx={{ width: 80 }}
          >
            {a.name}
          </Button>
        ))}
      </Group>
    </Card>
  )
}

const QRCode = ({ user }) => {
  const theme = useMantineTheme()
  const qrValue = `https://pouch.ph/${user?.username}`

  return (
    <Card
      title={
        <Group position='apart'>
          <Text size='xl' weight={700}>QR Code</Text>
          <Anchor href={`${qrValue}/qr`} target="_blank">
            <Button compact variant="transparent">PRINT</Button>
          </Anchor>
        </Group>
      }
    >
      <Center mt='sm'>
        <QRCodeCanvas
          value={qrValue}
          size={90}
          bgColor={theme.other[theme.colorScheme].backgroundColor}
          fgColor={theme.other[theme.colorScheme].text}
        />
      </Center>
    </Card>
  )
}

const RecentActivity = ({ user }) => {
  const history = useHistory()

  const handleViewAll = () => history.push('/pay/activity')

  return (
    <Card
      title={
        <Group position='apart'>
          <Text size='xl' weight={700}>Recent Activity</Text>
          <Button compact variant="transparent" onClick={handleViewAll}>
            VIEW ALL
          </Button>
        </Group>
      }
    >
      <ActivityTable user={user} />
    </Card>
  )
}

const Dashboard = ({ user }) => {
  return (
    <Box>
      <Group mb="xl" position='apart'>
        <Box>
          <Text weight={700}>Welcome!</Text>
          <Text weight={700}>{user?.name}</Text>
        </Box>
        <Text weight={700}>{format(Date.now(), 'EEEE | MMMM dd')}</Text>
      </Group>
      <Grid>
        <Grid.Col span={3} style={{ minHeight: 191 }}>
          <Balance user={user} />
        </Grid.Col>
        <Grid.Col span={6}>
          <Actions user={user} />
        </Grid.Col>
        <Grid.Col span={3}>
          <QRCode user={user} />
        </Grid.Col>
        <Grid.Col span={12}>
          <RecentActivity user={user} />
        </Grid.Col>
      </Grid>
    </Box >
  )
}

export default Dashboard
