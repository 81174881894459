import { useEffect, useState } from 'react'
import axios from 'axios'
import { format } from 'date-fns'
import {
  Text,
  Group,
  Grid,
  Divider,
  Alert,
  Loader,
} from '@mantine/core'
import { IconClipboard, IconUpload } from '@tabler/icons'
import { Button, Card, Select } from 'components'
import pouchConstants from 'pouchConstants'
import formatBalance from 'helpers/format-balance'
import convert from 'helpers/convert'
import BatchTable from './BatchTable'

const isReadClipboardSupported = 'readText' in navigator.clipboard

const Detail = ({ name, value }) => {
  return (
    <Group my='md' position="apart">
      <Text weight={700}>{name}</Text>
      {value}
    </Group>
  )
}

const Step1 = ({
  user,
  rows,
  setRows,
  setStep,
  activeProfileUsername,
  setActiveProfileUsername,
  activeProfile,
  totalAmount,
  setTotalAmount,
  profileIsRefreshing
}) => {
  const [tableError, setTableError] = useState('')
  const [paymentDetailsError, setPaymentDetailsError] = useState('')
  const [isTotalAmountLoading, setIsTotalAmountLoading] = useState(false)
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    calculateTotalAmount()
    return () => {
      setDidMount(false);
    }
  }, [rows])

  useEffect(() => {
    if (rows.length > 0) {
      validateBalanceToAmount(totalAmount)
    }
  }, [activeProfile, totalAmount])

  // Checks if current profile balance is enough for payment
  const validateBalanceToAmount = (amount) => {
    const isBalanceSufficient = activeProfile.balances[activeProfile.primaryCurrency] > amount
    setPaymentDetailsError(isBalanceSufficient ? '' : 'Insufficient balance')
  }

  const calculateTotalAmount = async () => {
    setIsTotalAmountLoading(true)
    let rates = {}
    try {
      const res = await axios.get('/api/v0/exchange-rates')
      rates = res.data.rates
    } catch (e) {
      console.log(e)
      setPaymentDetailsError('Error getting exchange rates. Please try again.')
      return
    }

    let sum = 0
    for (let i = 0; i < rows.length; i++) {
      const { amount, currency } = rows[i]
      if (currency in pouchConstants.CURRENCY_DICTIONARY && amount > 0 && amount < Number.MAX_SAFE_INTEGER) {
        const convertedAmount = convert(amount, currency, activeProfile.primaryCurrency, rates)
        sum += convertedAmount
      } else {
        setTotalAmount(0)
        setTableError('Invalid amount or currency found in one of the entries')
        setIsTotalAmountLoading(false)
        return
      }
    }

    setTableError('')
    if (sum === totalAmount) {
      // This is to execute the validateBalanceToAmount function if the previous total amount is the same
      validateBalanceToAmount(totalAmount)
    } else {
      setTotalAmount(sum)
      setPaymentDetailsError('')
    }
    setIsTotalAmountLoading(false)
  }

  const handleChangeProfile = (username) => {
    if (activeProfileUsername === username) { return }
    setActiveProfileUsername(username)
  }

  let accountType = 'Personal'
  if (activeProfile.businessMetadata.isBusiness) accountType = 'Business'
  if (activeProfile.businessMetadata.isRemittancePartner) accountType = 'Partner'
  if (activeProfile.businessMetadata.isRemittancePartnerAgent) accountType = 'Agent'

  const availableBalance = formatBalance(activeProfile.balances[activeProfile.primaryCurrency], activeProfile.primaryCurrency)
  const accounts = user.attachedProfiles.map(a => ({ value: a.username, label: a.username }))
  const accountsList = [{ value: user.username, label: user.username }, ...accounts]

  if (!didMount) return null;

  return (
    <>
      <Group mb='xl'>
        <Button variant="subtle" leftIcon={<IconClipboard />}>Paste from Spreadsheet</Button>
        <Divider orientation="vertical" />
        <Button variant="subtle" disabled leftIcon={<IconUpload />}>Upload File</Button>
      </Group>
      <Grid>
        <Grid.Col span={8}>
          <Card>
            <BatchTable
              isReadClipboardSupported={isReadClipboardSupported}
              rows={rows}
              setRows={setRows}
              activeProfile={activeProfile}
            />
          </Card>
        </Grid.Col>
        <Grid.Col span={4}>
          <Card title="Payment Details" sx={{ height: '100%' }}>
            <Select
              size="lg"
              data={accountsList}
              label="Select Account"
              value={activeProfileUsername}
              onChange={handleChangeProfile}
            />
            <Detail name="Account Type" value={<Text weight={700}>{accountType}</Text>} />
            <Detail
              name="Balance"
              value={isTotalAmountLoading
                ? <Loader size="sm" />
                : <Text weight={700}>{availableBalance}</Text>
              }
            />
            <Detail
              name="Date"
              value={<Text weight={700}>{format(Date.now(), 'MMMM dd, yyyy')}</Text>}
            />
            <Detail
              name="Users"
              value={<Text weight={700}>{rows.length}</Text>}
            />
            <Divider />
            <Detail
              name="Total Amount"
              value={isTotalAmountLoading
                ? <Loader size="sm" />
                : <Text weight={700}>{formatBalance(totalAmount, activeProfile.primaryCurrency)}</Text>
              }
            />
            {paymentDetailsError.length > 0
              ? <Alert my='xl' title={paymentDetailsError} color="red" />
              : null}
            <Button
              size='lg'
              disabled={profileIsRefreshing || isTotalAmountLoading || rows.length === 0 || tableError.length > 0 || paymentDetailsError.length > 0}
              fullWidth
              onClick={() => setStep(2)}
              radius="max"
            >
              Continue
            </Button>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  )
}
export default Step1
