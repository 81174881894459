import Layout from "./Layout"
const PrivacyPolicy = () => {
    return (
        <Layout>
PRODUCT DISCLOSURE STATEMENT
<br/><br/>
Introduction 
<br/>
About this Product Disclosure Statement (PDS)
<br/>
This Product Disclosure Statement (‘PDS’) is issued by Netbank, (a Rural Bank), Inc, which is a bank regulated by the BSP in the Philippines. (‘Issuer’). 
<br/>
This PDS is designed to assist you in deciding whether to open a White-labelled Bank Account. It is important that you read and understand this PDS. 
<br/>
 
<br/>
The information in this PDS does not take into consideration your individual financial situation, objectives, or needs. Prior to making any decision about the White-Labelled Bank Account or whether to acquire it, you should consider whether this product is right for you. Any advice in this PDS is general advice only. You should consider the appropriateness of any general advice to your own circumstances before acting on it. 
<br/>
For more information on the White-Labelled Bank Account, you may contact Pouch.  If you have additional questions, please contact Netbank, at the contact point below. 
<br/>
This PDS is dated October 1, 2023. 
<br/>
 
<br/>
Updates relating to this PDS 
<br/>
Information in this PDS that is not materially adverse information may be subject to change from time to time. This updated information will be available at https://www.netbank.ph/terms-and-conditions
<br/>
The information which the Netbank will make available by way of these updates may change from time to time. 
<br/>
General Description of Product 
<br/>
The Product is a White-Labelled Bank Account that is branded according to Pouch’s logo, color, and marks that is made available through a mobile/web application.
<br/>
The White-Labelled Bank Account may or may not earn interest depending on the product specifications provided through Pouch’s mobile/web application. The White-Labelled Bank Account is considered a deposit, hence, it is insured with the PDIC. 
<br/>
This White-Labelled Bank Account is issued by Netbank who is ultimately responsible to the bank account holders. 
<br/>
White-Labelled Bank Account Issuer 
<br/>
Netbank, as the Bank Account Issuer, holds a banking license with the Bangko Sentral ng Pilipinas (BSP) authorizing it to provide general banking, including this White-Labelled Bank Account program. 
<br/>
By acquiring this White-Labelled Bank Account, you are entering into a contractual relationship with the Issuer, not with Pouch or any of the parties involved in this product.  
<br/>
Pouch is authorized under agreement with the Issuer to act as a Cash and Sales Agent for the White-Labelled Bank Account. Pouch is authorized to arrange for the promotion, sale and customer service for the White-Labelled Bank Account. When providing financial services in relation to the promotion, sale and customer service for the deposit product, it acts on behalf of Netbank. 
<br/>
 
<br/>
Pouch can be contacted via: 
<br/>
Website: www.pouch.ph
<br/>
Email: support@pouch.ph
<br/>
Disputes 
<br/>
If you have a complaint, you can initially contact Pouch or also Netbank at the contact points above. Netbank will handle all complaints according to the Issuers internal dispute resolution procedure. 
<br/>
Our dispute resolution procedure requires that we provide an initial response to your complaint within 5 days, with a final response within 45 days. 
<br/>
If we are unable to resolve your complaint to your satisfaction after the 45 days, you may escalate the complaint to the Issuer at the contact details below. 
<br/>
Pouch is not the Issuer 
<br/>
Netbank and Pouch are independently managed and neither owns the other. Pouch does not act as Netbank’s agent on the inherent banking functions when they fulfil their roles and responsibilities in relation to the White-Labelled Bank Account. They act on their own behalf. 
<br/>
Neither Pouch nor anyone else acting on behalf of either entity, has the authority on behalf of Netbank to: 
<br/>
Tell you anything about the White-Labelled Bank Account that is inconsistent with the information in this PDS; 
<br/>
Give you financial product advice (that is, a recommendation or statement of opinion intended or that could be reasonably regarded as being intended to influence you in making a decision) about the White-Labelled Bank Account; or 
<br/>
Do anything else on Netbank’s behalf, other than marketing, arranging for the issue of and providing customer services for the White-Labelled Bank Account. 
<br/>
If you have been told anything that is inconsistent with this PDS, or if you have been given any financial product advice by Pouch or anyone seeming to act on behalf of Netbank, you should NOT rely on it in making any decisions about the White-Labelled Bank Account. You should only rely on what is in this PDS. 
<br/>
  
<br/>
Significant benefits of the product 
<br/>
There are some significant benefits to you associated with use of the Product, including: 
<br/>
The Product is a White-Labelled Bank Account product which is connected to PESONet and Instapay ACHs. This means that it can be used to make inter-bank fund transfers across PESONet and Instapay Institutions. 
<br/>
The White-Labelled Bank Account can be also used for bills payments and to buy load credits via the Bank’s accredited bills payment partners. 
<br/>
The White-Labelled Bank Account can receive funds (deposit/cash-in) and can withdraw funds (cash-out) via our physical branch and via accredited cash agent partners. 
<br/>
The White-Labelled Bank Account only accesses the value that you have deposited on to it.
<br/>
 
<br/>
Significant risks
<br/>
There are some significant risks associated with use of the White-Labelled Bank Account that you should be aware of, including: 
<br/>
Unauthorised transactions can happen using the White-Labelled Bank Account if your mobile phone, mobile phone number, E-mail address and the personal identification number (PIN) is compromised or revealed to an unauthorised person; or as a result of fraud. 
<br/>
You may not be able to get your money back if unauthorised or unintended transactions occur. 
<br/>
If the electronic network enabling the use of the White-Labelled Bank Account is unavailable (e.g. system downtime), you may not be able to undertake transactions or get information using the White-Labelled Bank Account. 
<br/>
You may only spend up to the value of the available balance on the White-Labelled Bank Account. 
<br/>
Other important information
<br/>
There are some other important things that you need to be aware of about the White-Labelled Bank Account: 
<br/>
It can generate any interest depending on the product specification. In other words, you can earn interest on the value deposited to the White-Labelled Bank Account if the product specifications provided through Pouch’s mobile/web application permits. 
<br/>
Certain limits apply to the use of the White-Labelled Bank Account: 
<br/>
Maximum Balance (Pending KYC)  PhP 0.00
<br/>
Fees & charges  
<br/>
Pouch will charge:
<br/>
Php 15 per outbound transfer via Instapay and Pesonet
<br/>
Customer Support
<br/>
Pouch is the primary customer support contact for a client, closely supported by Netbank. If your concern does not result in a resolution after 45 days, you may also contact Netbank via the contact details below.
<br/>
Email: cs@netbank.ph 
<br/>
Facebook: https://www.facebook.com/netbankPh/ 
<br/>
Website: www.netbank.ph
<br/>
Please provide your contact details and details of the situation. Netbank will register this contact and get back to you within 5 working days with an initial response. Netbank will seek to resolve all issues within 45 days.
<br/>
However, if we are unable to resolve your complaint to your satisfaction within 45 days then you may be eligible to escalate your complaint to the Bangko Sentral ng Pilipinas. Their contact details are as follows:
<br/>
Email: consumeraffairs@bsp.gov.ph
<br/>
Post: A. Mabini Street, 1004 Malate, Manila
<br/>
Ang Kasosyo ng Netbank ang pangunahing punto ng pakikipag-ugnayan para sa isang kliyente, na maigitng na tinutulungan and sinusuporthan din ng Netbank. Kung mangyaring ang iyong katanungan ay hindi pa din nareresolba pagkatapos ng 45 na araw, mangyaring makipag-ugnayan sa Netbank sa pamamagitan ng mga sumusunod na paraan:
<br/>
Email: cs@netbank.ph 
<br/>
Facebook: https://www.facebook.com/netbankPh/ 
<br/>
Website: www.netbank.ph
<br/>
Mangyaring ibigay ang iyong mga detalye sa pakikipag-ugnayan at mga detalye ng sitwasyon. Irerehistro ng Netbank ang contact na ito at babalikan ka sa loob ng 5 araw ng trabaho na may paunang tugon. Sisikapin ng Netbank na lutasin ang lahat ng isyu sa loob ng 45 araw.
<br/>
Kung mangyaring ang iyong katanungan ay hindi pa din nareresolba pagkatapos ng 45 na araw, maari ka ring makipag-ugnayan sa Bangko Sentral ng Pilipinas sa pamamagitan ng mga sumusunod na paraan:
<br/>
Email: consumeraffairs@bsp.gov.ph
<br/>
Post: A. Mabini Street, 1004 Malate, Manila
        </Layout>
    )
}
export default PrivacyPolicy
    