import React from 'react'
import { useMantineTheme, Group, Text, } from '@mantine/core'
import { BrandLogo } from 'components'

const Logo = () => {
  const theme = useMantineTheme()
  return (
    <Group
      spacing={10}
      sx={{ position: 'absolute', bottom: 20, right: 20 }}>
      <BrandLogo width={26} />
      <Text
        size={18}
        weight={700}
        sx={{ color: theme.colors.brand[6] }}
      >
        Pouch.ph
      </Text>
    </Group>
  )
}

export default Logo