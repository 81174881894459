import Layout from "./Layout"
const PrivacyPolicy = () => {
    return (
        <Layout>
            <h1>Support</h1>
            For assistance with matters related to your Pouch account, our support team can be reached via:
            <br/><br/>
            💬 Facebook Messenger - <a href="https://web.facebook.com/hellopouch">@hellopouch</a>
            <br/><br/>
            📧 Email - <a href="mailto:support@pouch.ph">support@pouch.ph</a>
            <br/><br/>
        </Layout>
    )
}
export default PrivacyPolicy
    