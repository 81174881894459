import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import { useForm } from '@mantine/form'
import {
  createStyles,
  Box,
  Button,
  Text,
  TextInput,
  PasswordInput,
  Checkbox,
  Input,
  Container,
  Group,
  Image,
  useMantineColorScheme,
  Title,
  Center
} from '@mantine/core'
import isValid from 'helpers/signup-validators'
import pouchLogo from 'assets/images/logo.svg'
import pouchLogoWhite from 'assets/images/logo-white.svg'

const Signup = ({ setUser }) => {
  const { classes } = useStyles()
  const history = useHistory()
  const { colorScheme } = useMantineColorScheme()
  const [activeStep, setActiveStep] = useState(0)
  const [usePhone, setUsePhone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [promos, setPromos] = useState({})
  const [promosError, setPromosError] = useState('')

  useEffect(() => {
    fetchPromos()
  }, [])

  const fetchPromos = async () => {
    try {
      const { data } = await axios.get('/api/v0/promo')
      setPromos(data)
    } catch (err) {
      setPromosError('Unable to load promos. Please refresh page.')
    }
  }

  const handleNext = () => setActiveStep((curr) => curr + 1)
  const handleBack = () => {
    setServerError(null)
    setActiveStep((curr) => curr - 1)
  }

  const form1 = useForm({
    initialValues: {
      fullName: '',
      phone: '+63 ',
      email: ''
    },
    validationRules: {
      fullName: (value) => isValid.name(value),
      phone: (value) => {
        const formattedPhone = value.replace(/\s/g, '')
        return usePhone ? isValid.phone(formattedPhone, 'PH') : true
      },
      email: (value) => usePhone ? true : isValid.email(value)
    },
    errorMessages: {
      fullName: 'Please use your full name',
      phone: 'Please use a valid phone number',
      email: 'Please use a valid email'
    }
  })

  const form2 = useForm({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
      promoCode: '',
      tosAccepted: false
    },
    validationRules: {
      username: (value) => isValid.username(value),
      password: (value) => isValid.password(value),
      confirmPassword: (value, values) => isValid.confirmPassword(value, values.password),
      promoCode: (value) => isValid.promoCode(value, promos),
      tosAccepted: (value) => value
    },
    errorMessages: {
      username: 'Username is not valid',
      password: 'Please use a stronger password',
      confirmPassword: 'Passwords do not match',
      promoCode: 'Promo code is invalid',
      tosAccepted: 'You must accept the terms of service.'
    }
  })

  const handlePhoneChange = (e) => {
    const prefix = '+63 '
    const number = e.currentTarget.value.slice(4).replace(/\D/g, '')
    let numberWithSpaces = ''
    for (let i = 0; i < 10; i++) {
      if (number[i]) {
        numberWithSpaces += number[i]
        if (i === 2 || i === 5) { numberWithSpaces += ' ' }
      }
    }
    form1.setFieldValue('phone', prefix + numberWithSpaces.trim())
  }

  const handlePromoCodeChange = (e) => {
    form2.setFieldValue('promoCode', e.currentTarget.value.toUpperCase())
  }

  const handleForm1Submit = form1.onSubmit(async () => {
    if (form1.validate()) {
      setLoading(true)

      const { phone, email } = form1.values
      const formattedPhone = phone.replace(/\s/g, '')
      const key = usePhone ? 'phone' : 'email'
      const val = usePhone ? formattedPhone : email

      await axios.get('/api/v0/user', { params: { [key]: val } })
        .then((res) => {
          if (res.data.user === null) {
            handleNext()
            setLoading(false)
          } else {
            const contact = usePhone ? 'phone number' : 'email address'
            setServerError(`This ${contact} is already used`)
            form1.setFieldError(key, true)
            setLoading(false)
          }
        })
        .catch(() => {
          setServerError('Server Error.')
          setLoading(false)
        })
    }
  })

  const handleForm2Submit = form2.onSubmit(async () => {
    if (form2.validate()) {
      setLoading(true)

      const { fullName, phone, email } = form1.values
      const { username, password, promoCode } = form2.values

      const res = await axios.get('/api/v0/user', { params: { username } })
      if (res.data.user) {
        setServerError('Username is already in use')
        form1.setFieldError('username', true)
        setLoading(false)
      } else {
        const data = {
          name: fullName,
          username,
          password,
          country: 'PH',
          primaryCurrency: 'PHP',
          promoCode
        }
        const formattedPhone = phone.replace(/\s/g, '')
        usePhone
          ? data.phone = formattedPhone
          : data.email = email

        await axios.post('/api/v0/user', data)
          .then((res) => {
            setUser(res.data.user)
            history.replace('/verify')
          })
          .catch((err) => {
            alert(err.response.data)
            setLoading(false)
          })
      }
    }
  })

  return (
    <Container className={classes.wrapper}>
      <Center>
        <Image src={colorScheme === 'light' ? pouchLogo : pouchLogoWhite} width={60} style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }} />
      </Center>
      <Title order={2} sx={{ textAlign: 'center', margin: '48px' }}>Create A Pouch Account</Title>
      {activeStep === 0
        ? <form onSubmit={handleForm1Submit}>
          <TextInput
            className={classes.fullName}
            size='lg'
            label='Full Name'
            placeholder='Full Name'
            {...form1.getInputProps('fullName')}
          />
          {usePhone
            ? <TextInput
              mt='lg'
              size='lg'
              label='Phone'
              placeholder='Phone'
              {...form1.getInputProps('phone', { type: 'number' })}
              onChange={handlePhoneChange}
              error={serverError || form1.errors.phone}
              onFocus={() => {
                setServerError(null)
                form1.setFieldError('phone', false)
              }}
            />
            : <TextInput
              mt='lg'
              size='lg'
              label='Email'
              placeholder='Email'
              {...form1.getInputProps('email', { type: 'email' })}
              error={serverError || form1.errors.email}
              onFocus={() => {
                setServerError(null)
                form1.setFieldError('email', false)
              }}
            />}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant='subtle' onClick={() => setUsePhone((curr) => !curr)} sx={{ padding: 10 }}>
              Use {usePhone ? 'email' : 'phone'} instead
            </Button>
          </Box>
          <Button mt='xl' size='lg' radius='md' fullWidth loading={loading} type='submit'>Next</Button>
          <Text className={classes.backButton} onClick={handleBack}>Back</Text>
        </form>
        : null}

      {activeStep === 1
        ? <form onSubmit={handleForm2Submit}>
          <TextInput
            size='lg'
            label='Username'
            placeholder='Username'
            {...form2.getInputProps('username')}
            error={serverError || form2.errors.username}
            onFocus={() => {
              setServerError(null)
              form2.setFieldError('username', false)
            }}
          />
          <PasswordInput
            mt='lg'
            size='lg'
            label='Password'
            placeholder='Password'
            {...form2.getInputProps('password')}
          />
          <PasswordInput
            mt='lg'
            size='lg'
            label='Confirm Password'
            placeholder='Confirm Password'
            {...form2.getInputProps('confirmPassword')}
          />
          <TextInput
            mt='lg'
            size='lg'
            label='Promo Code (optional)'
            placeholder='Promo Code'
            {...form2.getInputProps('promoCode')}
            error={form2.errors.promoCode || promosError}
            onChange={handlePromoCodeChange}
          />
          <Text mt='sm'>{promos[form2.values.promoCode]?.description}</Text>
          <Input.Wrapper size='lg' error={!form2.values.tosAccepted && form2.errors.tosAccepted}>
            <Group mt='lg'>
              <Checkbox {...form2.getInputProps('tosAccepted', { type: 'checkbox' })} />
              <Text>I have read and accept Pouch’s
                <Link to='/terms-of-service' target='_blank' sx={{ display: 'inline' }}> Terms of Service </Link> and
                <Link to='/privacy' target='_blank'> Privacy Policy</Link>.
              </Text>
            </Group>
          </Input.Wrapper>
          <Button mt='xl' size='lg' radius='md' fullWidth loading={loading} type='submit'>Finish</Button>
          <Text className={classes.backButton} onClick={handleBack}>Back</Text>
        </form>
        : null}
    </Container>
  )
}

export default Signup

const useStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: 556
  },
  backButton: {
    marginTop: theme.spacing.lg,
    color: theme.other[theme.colorScheme].text,
    textAlign: 'center',
    ':hover': {
      cursor: 'pointer',
      userSelect: 'none'
    }
  },
  fullName: {
    div: {
      input: {
        textTransform: 'capitalize'
      }
    }
  }
}))
