import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useMantineTheme, AppShell, Header, Space, Container, Box, Center } from '@mantine/core'
import Button from './Button'
import ColorSchemeToggle from './ColorSchemeToggle'
import BrandLogo from './BrandLogo'

const CustomHeader = ({ setUser }) => {
  const { pathname } = useLocation()
  const history = useHistory()

  let text = ''
  let location = ''

  if (pathname === '/pay/signin') {
    location = '/signup'
    text = 'Sign Up'
  } else if (pathname === '/pay/signup') {
    location = '/pay/signin'
    text = 'Sign In'
  } else if (pathname === '/pay/verify') {
    location = '/pay/signin'
    text = 'Sign Out'
  }

  const handleSignout = () => {
    axios.delete('/api/v0/auth').then(() => {
      setUser(null)
    })
  }

  const handleClick = () => {
    if (pathname === '/verify') {
      handleSignout()
    }
    history.replace(location)
  }

  return (
    <Header fixed height={100} p='xl'>
      <Center>
        <BrandLogo />
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
          <ColorSchemeToggle />
          <Space w={20} />
          <Button radius='xl' onClick={handleClick} sx={{ height: 40 }}>{text}</Button>
        </Box>
      </Center>
    </Header>
  )
}

const PayAuthLayout = ({ setUser, children }) => {
  return (
    <AppShell fixed header={<CustomHeader height={70} padding='xs' setUser={setUser} />}>
      <Container size='xl' sx={{ marginTop: 100 }}>
        {children}
      </Container>
    </AppShell>
  )
}

export default PayAuthLayout
