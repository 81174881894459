import Layout from "./Layout"

const TermsOfService = () => {
    return (
        <Layout>
            TERMS OF SERVICE

<br/>

LAST UPDATED 11 SEPTEMBER 2023

<br/>



<br/>

Terms and Conditions Governing the Use and Maintenance of Financial Service Accounts with Pouch Philippines, Inc.

<br/>



<br/>

Pouch Philippines, Inc., (“Pouch”, “Pouch.ph”, “we”, “us”) is a registered Operator of Payment System duly supervised and regulated by the Bangko Sentral ng Pilipinas (“BSP”) and not a bank. Its Services are powered Netbank (A Rural Bank) Inc. (“Netbank” or “Banking Partner”), a fully regulated banking institution by the BSP.

<br/>



<br/>

The user of the financial service platform (individually referred to as the “User” and collectively as the “Users”) hereby agrees to be governed by the following terms and conditions governing the use and maintenance of their mobile banking financial service account (the “Account”, “Financial Service Account”, “Financial Service Platform”, or “Services”).

<br/>



<br/>

Furthermore, the User agrees to comply with relevant laws, rules, and regulations of the BSP, Bankers Association of the Philippines (“BAP”), as well as any subsequent amendments or supplements thereof and any new laws or rules that apply to the Financial Service Account.

<br/>



<br/>

General Provisions

<br/>



<br/>

This Agreement outlines the terms between you and Pouch regarding the use of its Services.

<br/>



<br/>

By registering and using the financial service platform of Pouch, you agree to these terms. We may update this Agreement and we reserve the right to modify or discontinue our Services at any time. 

<br/>



<br/>

Credits to the Financial Service Account

<br/>



<br/>

Credits shall be added to the Account after verification by Pouch. The verified amount shall be considered the correct amount credited by the User. Any discrepancies thereof will be communicated to the User.

<br/>



<br/>

We may, in our absolute discretion, refuse or cancel Transaction Requests, Payment Requests or Transactions if: (a) Pouch is unable to verify your identity; (b) Pouch is unable to verify the identity of the Recipient; (c) You do not comply with information requests pursuant to compliance with AMLA or with other laws, rules, or regulations; or (d) Pouch reasonably believes you are using the Service, or allowing it to be used, in breach of these Terms of Service or any applicable laws, rules or regulations.

<br/>



<br/>

Loss or Unauthorized Use of the Financial Service Account

<br/>



<br/>

Pouch shall not be held responsible for any transactions made prior to its notice of unauthorized use of the Account, except in cases of evident acts of forgery or irregularity.

<br/>



<br/>

Service Account Balance

<br/>



<br/>

Except for manifest errors, the User acknowledges that the outstanding balance of the Account, as shown in the records of Pouch is accurate and binding. The User further waives his or her rights under provisions of Article 1250 of the New Civil Code over the said Account.

<br/>



<br/>

Right to Close the Financial Service Account

<br/>



<br/>

Pouch or its Banking Partner, Netbank, reserves the right to close the Account immediately upon notice, at any time and from time to time, if it is mishandled due to misrepresentation by the User or for any other justifiable reason within applicable laws and regulations of the Philippines. The User agrees to hold Pouch or Netbank harmless from all liabilities arising from such actions.

<br/>



<br/>

General Transaction Requests

<br/>



<br/>

i. We are not obliged to process any particular Transaction. When you submit a Transaction Request, you are requesting that we process the Transaction on your behalf and consenting to the execution of the Transaction. We may, in our sole discretion, choose whether or not to accept the offer to process that Transaction. If we decide not to process the Transaction, we will notify you promptly of that decision and repay to you the Transaction Amount received by us, provided that we are not prohibited by law from doing so. If we choose to proceed with the Transaction, we may still suspend or cancel it in our discretion.

<br/>



<br/>

ii. We will attempt to process Transactions promptly, but any Transaction may be delayed or cancelled for a number of reasons including but not limited to: our efforts to verify your identity; to validate your Transaction instructions; to contact you; due to variations in banking days, business hours, and currency availability; or otherwise to comply with applicable laws, rules or regulations.

<br/>



<br/>

iii. If we have executed the Transaction in accordance with the instructions you have provided to us, and that the instructions prove to have been incorrect, we are not liable for the incorrect execution of the Transaction. We will however make reasonable efforts to recover the funds. We may charge you a reasonable fee, reflective of our efforts, to do so. Unless there are exceptional circumstances, no adjustment will be made for any currency fluctuations which may have occurred between the time you pay us the Transaction Amount and the time of credit.

<br/>



<br/>

iv. Pouch shall have the right to automatically suspend or block the transaction or any Financial Service Account in the event that Pouch has reason to believe that the transaction or the account may be illegal or used for fraudulent or suspicious businesses or by an unauthorized person. Pouch may, but shall not have the obligation to, inform you prior to suspending or blocking the transaction or the Pouch Financial Service Account pursuant to this clause. You acknowledge the authority of Pouch to suspend or block the transaction and the Pouch Financial Service Account. Accordingly, you shall hold Pouch free and harmless against any and all consequences of such suspension or blocking, or any loss or damage which you may suffer as a result thereof.

<br/>



<br/>

Anti-Money Laundering

<br/>



<br/>

Users of Pouch agree to comply with Republic Act No. 9160, known as the “Anti-Money Laundering Act of 2001”, and any subsequent amendments or regulations. Users indemnify Pouch, its directors, officers, employees, staff, and its banking partner from liabilities arising from actions taken in compliance with this act. Users warrant that their accounts will not be used for money laundering or funding terrorism and that funds will not originate from illegal activities. Pouch may disclose account information as required by regulatory agencies, both domestic and international, in connection with Anti-Money Laundering regulations.

<br/>



<br/>

Set-off

<br/>



<br/>

Pouch is authorized to charge the Account to settle any obligations owed by the User, including fees, penalties, charges, and other dues in favor of Pouch as contained in this Terms of Service or in such other form communicated to the User. The User shall not hold Pouch liable for any consequences arising from such actions.

<br/>



<br/>

Deposit Insurance

<br/>



<br/>

Funds held within the Account are insured by the Philippine Deposit Insurance Corporation (“PDIC”) up to a maximum amount of P500,000.00 per User or as otherwise set by law. 

<br/>



<br/>

PDIC will assume that the name/s on the Account are the actual/beneficial owner/s, unless otherwise specified. 

<br/>



<br/>

For transfers or break-ups of funds, PDIC will recognize the beneficial ownership of transferees who are qualified relatives of the transferor. The relationship between the transferor and transferee must be proven by relevant documents. For deposits in the name of entities or transfers to non-qualified relatives, PDIC will recognize beneficial ownership provided the Account records show the necessary details. PDIC may require additional documents to ascertain the details of the transfer.

<br/>



<br/>

Interest

<br/>



<br/>

Funds with a minimum balance as prescribed by Pouch, as may be communicated via the digital application system (“App”), will earn interest based on prevailing rates as determined by the policies and regulations observed by its banking partner. Interest will be computed based on the average daily balance (“ADB”) of the Account and credited on the last day of the interest period. No interest will be paid on accounts closed prior to the prescribed Interest Payment Date, even if they meet the required minimum balance. All interest earned is subject to the prevailing withholding tax rate, unless exempted by the Bureau of Internal Revenue (“BIR”).

<br/>



<br/>

Fees and Charges over the Financial Service Account

<br/>



<br/>

You agree to pay fees, rates and other charges and applicable taxes, related to the Service and its use, as may be imposed by Pouch and its Banking Partner.

<br/>



<br/>

A non-refundable fee will be charged for:

<br/>



<br/>

a. Active Accounts falling below the required ADB for two consecutive months;

<br/>



<br/>

b. Dormant Accounts falling below the required ADB;

<br/>



<br/>

c. Accounts closed within a month after opening;

<br/>



<br/>

d. Requests for issuance of account balance certifications;

<br/>



<br/>

e. Other services related to the Account as determined by Pouch, such as maintenance fees and/or fees to be imposed by its banking partner.

<br/>



<br/>

We will notify you of all applicable fees and charges, which will be debited automatically from the affected Account. If the affected account lacks sufficient funds, Pouch may temporarily freeze and place in dormancy the account of the user and/or deduct the required amount from other Financial Service Accounts held by the User, if any.

<br/>



<br/>

Average Daily Balance

<br/>



<br/>

Users must maintain the required minimum monthly ADB. Pouch will notify Users of any changes in the required ADB and potential fees at least sixty days prior to the imposition of said fees. This notification will be delivered via the App or other communication methods. Costs of notifications will be debited from the Service Account, if any.

<br/>



<br/>

Unclaimed Balances

<br/>



<br/>

In accordance with Act 3936 as amended by PD 679, all unclaimed balances held within the Pouch Finacial Service Account for a User known to be deceased or inactive for ten years or more will be transferred to the credit of the Republic of the Philippines.

<br/>



<br/>

Authority to Withhold and Set-off

<br/>



<br/>

Pouch is authorized to set-off or withhold funds as security for any obligations the User has. This includes all funds, properties, or securities of the User in the possession of Pouch through this banking partner. Pouch can apply these to pay any obligations incurred by the User under the Financial Service Account or any other transactions.

<br/>



<br/>

Reserved Rights

<br/>



<br/>

You understand and agree that Pouch reserves the right, at its sole discretion but without obligation, to deny access to and/or discontinue the Services or any component thereof to anyone at any time, temporarily or permanently, without giving any reason and/or prior notice to you.

<br/>



<br/>

You hereby irrevocably agree, affirm, and warrant to hold Pouch and its banking partner free from any liability, both under equity and law, arising or that may arise out of any such denial of access to or discontinuance of the Services.

<br/>



<br/>

Disclosure of Information

<br/>



<br/>

Users acknowledge that various government regulatory agencies may require Pouch to disclose information concerning their accounts. 

<br/>



<br/>

Users waive their rights under Republic Act 1405, Republic Act 6426, RA 8791, or any other relevant law and authorize Pouch to disclose such information as required. Users also consent to the sharing of relevant data to prevent and detect fraud and unlawful access to their accounts.

<br/>



<br/>

For the avoidance of doubt, to the extent that applicable confidentiality, bank secrecy or other laws impose non-disclosure requirements on certain relevant information but permits a party to waive such requirements by written consent, the express consent provided herein shall constitute my written consent and waiver for purposes of such applicable laws. 

<br/>



<br/>

Non-residents with Peso Accounts

<br/>



<br/>

Non-residents may open a Peso Service Account with Pouch, subject to approval and applicable Philippine laws. Users are responsible for any taxes related to the Financial Service Account. Non-resident Users must provide their taxpayer identification number from their home country and waive any bank secrecy or data protection rights related to their Financial Service Account.

<br/>



<br/>

Binding Effect

<br/>



<br/>

These terms and conditions are binding on all Users and their respective heirs, executors, administrators, and assigns.

<br/>



<br/>

Governing Law

<br/>



<br/>

These terms and conditions are governed by the laws of the Republic of the Philippines.

<br/>



<br/>

Foreign Account Tax Compliance Act (“FATCA”) Compliance

<br/>



<br/>

Users certify that all information provided to Pouch is accurate and up-to-date. Users waive their rights of confidentiality and authorize Pouch to report and disclose any required information to the U.S. IRS, Philippine Regulatory Agencies, or any third party authorized to receive FATCA information. 

<br/>



<br/>

Users agree to inform Pouch of any changes in their circumstances affecting their status within thirty days. Users will indemnify Pouch from liabilities arising from false or inaccurate information provided.

<br/>



<br/>

Unauthorized Uses

<br/>



<br/>

Users are prohibited from using Pouch's Services for activities ("Unauthorized Uses") that contravene this Agreement. Engaging in Unauthorized Uses can lead to service disruptions, account deactivation, or legal actions. If unsure about a particular use, contact Pouch's support.

<br/>



<br/>

Prohibited Uses include transactions or activities related to:

<br/>



<br/>

Investment Schemes: Avoid pyramid schemes, unlicensed investment vehicles, and deceptive charity schemes.

<br/>



<br/>

Fraud: Do not provide misleading information for financial gain.

<br/>



<br/>

Gambling: Avoid online gambling, lotteries, and other games of chance.

<br/>



<br/>

Unauthorized Financial Institutions: Avoid unlicensed money transmission and unregulated financial operations.

<br/>



<br/>

Drugs: Avoid illegal narcotics and controlled substances.

<br/>



<br/>

Stolen Items: Do not sell items without clear title.

<br/>



<br/>

Intellectual Property Infringement: Avoid selling counterfeit or unauthorized goods.

<br/>



<br/>

Shell Companies: Entities without genuine business purposes are prohibited.

<br/>



<br/>

Bearer Shares Entities: Transactions with entities with unknown beneficial owners are prohibited.

<br/>



<br/>

Adult Services and Media: Avoid services involving illegal adult content.

<br/>



<br/>

High-Risk Entities: Entities posing high risks are unauthorized users.

<br/>



<br/>

Violence: Avoid violent acts or items promoting violence.

<br/>



<br/>

Coercion: Avoid extortion or blackmail.

<br/>



<br/>

Weapon Sales: Avoid unlicensed sale of weapons.

<br/>



<br/>

Allowing the Account to be Utilized by a Third Party: 1) selling, leasing or giving your Account to a third party, 2) earning monies, goods or services by performing transactions using the Services for a third party, and 3) acting as a remittance agent using the Services

<br/>



<br/>

Limitation of Liability

<br/>



<br/>

You agree to hold the Pouch, its banking partner, its affiliates, successors and assigns, including any of their respective directors, officers, agents and representatives, free and harmless, as well as indemnify them, from any and all liabilities, claims, damages, suits of whatever nature, arising out of or in connection with the implementation of this Agreement and/or my availment of the Financial Service Account, including, but not limited to, the Bank’s failure to effect any Account balance request, funds transfer transaction, bills payment transaction, stop payment order request, and/or such other services, as well as, on account of any transaction that I may undertake using the mobile banking financial services unless the loss or damage I actually incurred is solely and directly caused by the gross negligence or willful misconduct of Pouch or its banking partner.

<br/>



<br/>

Pouch and its banking partner shall not be liable for any losses or damages resulting from circumstances over which its has no direct control, including, but not limited to, unauthorized access to any OTP sent to my Registered Mobile Number, my disclosure of my personal information, Login Credentials, Mobile Key and/or OTP to other persons – regardless of whether such disclosure was intentional or inadvertent, the failure of electronic or mechanical equipment or communication lines or other interconnection problems, system downtime, pandemics, epidemics, government actions, severe weather, earthquakes, floods, fire or other such other similar events.

<br/>



<br/>

To the full extent allowed by applicable laws, Pouch and its banking partner shall not be liable to you for any special, consequential, indirect, exemplary, incidental or punitive damages, including but not limited to lost profits or actual or anticipated revenue, lost opportunities and business interruption, howsoever caused, whether under a theory of contract, warranty, tort (including negligence), product liability, or otherwise, arising out of or in connection with this Agreement or its termination, and irrespective of whether you have been advised by Pouch or its banking partner of the possibility of any such loss or damage.

<br/>



<br/>

No warranty

<br/>



<br/>

Our services are provided "as is" without any warranties, either express or implied.

<br/>



<br/>

Force Majeure

<br/>



<br/>

Pouch is not liable for any disruptions or failures beyond our reasonable control, including natural disasters, civil unrest, or other unforeseen circumstances.

<br/>



<br/>

Security and Liability

<br/>



<br/>

Pouch and its banking partner commit to maintaining the security of its systems. In the event of a security breach or system failure due to our negligence, we will notify affected customers and take necessary actions. 

<br/>



<br/>

We will defend and indemnify affected customers from third-party claims arising from such incidents, except when caused by the customer's actions or unforeseen events.

<br/>



<br/>

Governing Law and Venue of Litigation

<br/>



<br/>

This Terms of Service shall be construed and governed in accordance with the laws of the Philippines.

<br/>



<br/>

You understand and agree that these Terms including all disputes relative to your use of the Services shall be governed by the laws of the Republic of the Philippines without regard to conflict of laws provisions. In case of any dispute arising out of these Terms of Service or your use of the Services, you hereby agree, undertake and commit to resolve or attempt to resolve such dispute promptly and in good faith. In case of failure to resolve any such dispute, you agree to the jurisdiction by and venue in the appropriate courts of Makati City, Philippines, to the exclusion of all other courts, and waive as you hereby waive, any objection to such jurisdiction or venue.

<br/>



<br/>

Time Limitation on Claims

<br/>



<br/>

Any claims related to this Agreement must be filed within one year from when the claim arose.

<br/>



<br/>

Export Controls & Sanctions

<br/>



<br/>

Pouch and the services of its banking partner comply with local and international export controls and sanctions. Users must ensure their transactions comply with these regulations.

<br/>



<br/>

Relationship Between Pouch and the User

<br/>



<br/>

Pouch is an independent service provider. We prioritize your privacy and will handle your personal information as outlined in our Data Privacy Statement. We will not use or sell your personal information for unrelated third-party marketing unless instructed by you. Any intellectual property related to the services of Pouch remains its property.

<br/>



<br/>

Password Security 

<br/>



<br/>

Users are responsible for maintaining the security of their account credentials. Regularly update your password and ensure your contact information is current.

<br/>



<br/>

Amendments

<br/>



<br/>

Pouch may at any time and for whatever reason it may deem proper, amend, revise, or modify these Terms of Service immediately upon notice. 

<br/>



<br/>

User/Account Holder agrees that for purposes of this Terms of Service, publication of the revised Terms of Service in Pouch’s website shall be considered as sufficient notice. It is the Account Holder’s responsibility to regularly check any changes to these Terms and Conditions at www.pouch.ph/terms-and-conditions. The Account Holder’s continued use of Pouch's Services after any such changes constitutes acceptance of the new Terms of Service. 

<br/>



<br/>

Failure to notify Pouch of Account Holder’s intention to terminate his/her account shall be construed as acceptance by the Account Holder of the amendments to these Terms of Service.

<br/>



<br/>

Notice

<br/>



<br/>

All communications and notices to Users shall be through available channels, including but not limited to in-App notifications, SMS, email, or registered mail which shall be sent to your registered address. Notices are deemed received based on the delivery method used. Users are responsible for keeping their contact information updated with Pouch.

<br/>



<br/>

Customer Service

<br/>



<br/>

If you have any concerns about a procedure or have encountered a problem with our service, we will do our best to address it within seven (7) banking days. Should your concern require more time to be resolved, we will update you every step of the way.

<br/>



<br/>

You may contact our Customer Service number and social media network at: support@pouch.ph | https://www.facebook.com/hellopouch/
        </Layout>
    )
}
export default TermsOfService
