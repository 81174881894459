import React, { useState, useEffect, useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { Box, Text, Modal, ScrollArea } from '@mantine/core'
import { Card, Table, Button, TextViewMore } from 'components'
import axios from 'axios'

const Activity = ({ user }) => {
  const [opened, setOpened] = useState(false)
  const [viewTx, setViewTx] = useState({})
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getTransactions()
    return () => {
      setTableData([])
    }
  }, [])

  const getTransactions = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get('/api/v0/bridge/transaction/list')
      setTableData(data)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const handleView = (tx) => {
    setViewTx(tx)
    setOpened(true)
  }

  const allData = useMemo(() => {
    return tableData.map((tx) => {
      return {
        id: tx._id,
        status: tx.status,
        sender: tx.sender?.entityIdentifier,
        recipient: tx.recipient?.entityIdentifier,
        amount: `${tx.recipient?.total || tx.sender?.total} ${tx.recipient?.currency || tx.sender?.currency}`,
        date: tx.updatedAt,
        actions: tx
      }
    }, [])
  }, [tableData])

  const queuedData = useMemo(() => {
    const queuedTxs = [...tableData].filter(tx => tx.status === 'stuck' && tx.recipient.entityType !== 'lightning-node')
    return queuedTxs.map((tx) => {
      return {
        id: tx._id,
        status: tx.status,
        sender: tx.sender?.entityIdentifier,
        recipient: tx.recipient?.entityIdentifier,
        amount: `${tx.recipient?.total || tx.sender?.total} ${tx.recipient?.currency || tx.sender?.currency}`,
        date: tx.updatedAt,
        actions: tx
      }
    }, [])
  }, [tableData])

  const columns = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ value }) => value ? <TextViewMore text={value} maxLength={8} /> : null
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Sender',
      accessor: 'sender',
      Cell: ({ value }) => value ? <TextViewMore text={value} maxLength={16} /> : null
    },
    {
      Header: 'Recipient',
      accessor: 'recipient',
      Cell: ({ value }) => value ? <TextViewMore text={value} maxLength={16} /> : null
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Date & Time',
      accessor: 'date',
      Cell: ({ value }) => {
        const parsedDate = parseISO(value)
        return (
          <Box sx={{ width: 120 }}>
            <Text>{format(parsedDate, 'dd MMM yyyy')}</Text>
            <Text size="sm">{format(parsedDate, 'hh:mm aa xxxxx')}</Text>
          </Box>
        )
      }
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ value }) => (
        <Button variant='transparent' onClick={() => handleView(value)}>View</Button>
      )
    },
  ], [])

  return (
    <>
      <Modal
        size='lg'
        opened={opened}
        onClose={() => setOpened(false)}
        title={`Transaction ${viewTx?._id}`}
      >
        <ScrollArea type="always">
          <Text size='lg'>
            <pre>{JSON.stringify(viewTx, null, 2)}</pre>
          </Text>
        </ScrollArea>
      </Modal>
      <Card title='All (Most recent 250)'>
        <Table
          data={allData}
          columns={columns}
          striped
          height={588}
          loading={loading}
        />
      </Card>
    </>
  )
}

export default Activity
