import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

const BatchPayment = ({ user }) => {
  const [step, setStep] = useState(1)
  const [rows, setRows] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [activeProfileUsername, setActiveProfileUsername] = useState(user.username)
  const [activeProfile, setActiveProfile] = useState(user)
  const [profileIsRefreshing, setProfileIsRefreshing] = useState(false)
  const [response, setResponse] = useState(null)

  useEffect(() => {
    refreshActiveProfile()
    return () => {
      setActiveProfile(null)
    }
  }, [activeProfileUsername])

  const refreshActiveProfile = async () => {
    setProfileIsRefreshing(true)
    await axios.get(`/api/v0/user?username=${activeProfileUsername}`)
      .then(res => {
        setActiveProfile(res.data.user)
        setProfileIsRefreshing(false)
      })
      .catch(err => {
        setProfileIsRefreshing(false)
        console.error(err)
      })
  }

  return (
    <>
      {step === 1
        ? <Step1
          user={user}
          rows={rows}
          setRows={setRows}
          setStep={setStep}
          activeProfileUsername={activeProfileUsername}
          setActiveProfileUsername={setActiveProfileUsername}
          activeProfile={activeProfile}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
          profileIsRefreshing={profileIsRefreshing}
        />
        : null}
      {step === 2
        ? <Step2
          rows={rows}
          totalAmount={totalAmount}
          setStep={setStep}
          activeProfileUsername={activeProfileUsername}
          primaryCurrency={activeProfile.primaryCurrency}
          setResponse={setResponse}
        />
        : null}
      {step === 3
        ? <Step3
          response={response}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
          totalUsers={rows.length}
          activeProfileUsername={activeProfileUsername}
          primaryCurrency={activeProfile.primaryCurrency}
          setRows={setRows}
          setStep={setStep}
          refreshActiveProfile={refreshActiveProfile}
        />
        : null}
    </>
  )
}

export default BatchPayment
