import React, { useState, useEffect, useMemo, useRef, forwardRef } from 'react'
import { format, parseISO } from 'date-fns'
import { CSVLink } from 'react-csv'
import { useMantineTheme, createStyles, Box, Text, Group, Notification, Popover, Select } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Card, Table, Button, TextViewMore } from 'components'
import { DocumentDownload } from 'iconsax-react';
import { IconExclamationMark, IconCalendarEvent, IconChevronDown } from '@tabler/icons'
import { getSender, getRecipient, getAmount } from 'helpers/transactions-table-helpers'
import axios from 'axios'

const MAX_LENGTH = 17

const headers = [
  { label: 'Date & Time', key: 'date' },
  { label: 'Transaction Type', key: 'type' },
  { label: 'Sender', key: 'sender' },
  { label: 'Recipient', key: 'recipient' },
  { label: 'Amount', key: 'amount' },
  { label: 'Status', key: 'status' }
]
const dateFormat = 'DD/MMM/YYYY'

const PopoverButton = forwardRef(({ disabled, children, ...rest }, ref) => {
  const theme = useMantineTheme()
  const color = theme.other[theme.colorScheme].text
  return (
    <div ref={ref} {...rest}>
      <Button
        variant='transparent'
        rightIcon={<IconChevronDown color={color} />}
        disabled={disabled}
        sx={{ color }}
      >
        {children}
      </Button>
    </div>
  )
});
PopoverButton.displayName = 'PopoverButton';

const DateRange = ({ fromDate, setFromDate, toDate, setToDate, handleApplyDateRange, loading }) => {
  return (
    <Popover position="bottom">
      <Popover.Target>
        <PopoverButton disabled={loading}>Date Range</PopoverButton>
      </Popover.Target>
      <Popover.Dropdown>
        <Group spacing='xs' my='md'>
          <Text sx={{ width: 50 }}>From</Text>
          <DatePicker
            value={fromDate}
            onChange={setFromDate}
            placeholder={dateFormat}
            inputFormat={dateFormat}
            icon={<IconCalendarEvent stroke={1.5} />}
            maxDate={toDate}
          />
        </Group>
        <Group spacing='xs' my='md'>
          <Text sx={{ width: 50 }}>To</Text>
          <DatePicker
            value={toDate}
            onChange={setToDate}
            placeholder={dateFormat}
            inputFormat={dateFormat}
            icon={<IconCalendarEvent stroke={1.5} />}
            minDate={fromDate}
          />
        </Group>
        <Group position='right'>
          <Button onClick={handleApplyDateRange} disabled={loading}>
            Apply
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
}

const Activity = ({ user }) => {
  const { classes } = useStyles()
  const [activeProfile, setActiveProfile] = useState(user)
  const [activeProfileUsername, setActiveProfileUsername] = useState(user.username)
  const [tableData, setTableData] = useState([])
  const [totalTx, setTotalTx] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [exportData, setExportData] = useState([])
  const [exportError, setExportError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const csvRef = useRef(null)

  useEffect(() => {
    refreshActiveProfile()
    return () => {
      setActiveProfile(null)
    }
  }, [activeProfileUsername])

  const refreshActiveProfile = async () => {
    await axios.get(`/api/v0/user?username=${activeProfileUsername}`)
      .then(res => {
        setActiveProfile(res.data.user)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const columns = useMemo(() => [
    {
      Header: 'Date & Time',
      accessor: 'date',
      Cell: ({ value }) => {
        const parsedDate = parseISO(value)
        return (
          <Box sx={{ width: 120 }}>
            <Text>{format(parsedDate, 'dd MMM yyyy')}</Text>
            <Text size="sm" weight={400}>
              {format(parsedDate, 'hh:mm aa xxxxx')}
            </Text>
          </Box>
        )
      }
    },
    {
      Header: 'Transaction Type',
      accessor: 'type',
    },
    {
      Header: 'Sender',
      accessor: 'sender',
      Cell: ({ value }) => value ? <TextViewMore text={value} maxLength={MAX_LENGTH} /> : null
    },
    {
      Header: 'Recipient',
      accessor: 'recipient',
      Cell: ({ value }) => value ? <TextViewMore text={value} maxLength={MAX_LENGTH} /> : null
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => (
        <Text
          weight={700}
          className={
            value.status === 'completed' &&
              value.entityIdentifier === activeProfileUsername
              ? classes.addedAmount
              : null
          }
        >
          {value.amount}
        </Text>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <Text className={classes[value]} transform="uppercase" weight={700}>
          {value}
        </Text>
      )
    },
  ], [])

  const data = useMemo(() => {
    return tableData.reduce((prev, curr) => {
      const row = {
        date: curr.transactionDate,
        type: curr.transactionType,
        sender: getSender(curr, activeProfileUsername, true) ?? '',
        recipient: getRecipient(curr, activeProfileUsername, true) ?? '',
        amount: {
          amount: getAmount(curr, activeProfileUsername, activeProfile.primaryCurrency),
          status: curr.status,
          entityIdentifier: curr.recipientName
        },
        status: curr.status
      }
      return [...prev, row]
    }, [])
  }, [tableData])

  useEffect(() => {
    fetchActivity(pageNum)
  }, [pageNum])

  const fetchActivity = async (page) => {
    const data = await getTransactions(page)
    setTableData(data.results)
    setTotalTx(data.total)
    setTotalPages(data.totalPages)
  }

  const getTransactions = async (page, limit) => {
    setLoading(true)
    try {
      let url = `/api/v0/transactions?page=${page}&activeProfileUsername=${activeProfileUsername}`
      if (fromDate) url += `&fromDate=${new Date(fromDate).toISOString()}`
      if (toDate) url += `&toDate=${new Date(toDate).toISOString()}`
      if (limit) url += `&limit=${limit}`
      const { data } = await axios.get(url)
      setLoading(false)
      return data
    } catch (err) {
      setLoading(false)
      setExportLoading(false)
      console.error(err)
    }
  }

  const handleApplyDateRange = async () => {
    if (pageNum !== 1) {
      setPageNum(1)
    } else {
      fetchActivity(1)
    }
  }

  const formatExportData = (data) => {
    return data?.reduce((prev, curr) => {
      return [...prev, {
        date: format(parseISO(curr.transactionDate), 'dd MMM yyyy hh:mm aa xxxxx'),
        txType: curr.transactionType,
        sender: getSender(curr, activeProfileUsername) ?? '',
        recipient: getRecipient(curr, activeProfileUsername) ?? '',
        amount: getAmount(curr, activeProfileUsername, curr.currency),
        status: curr.status.replace(/_/g, ' ')
      }]
    }, [])
  }

  const handleDownloadCSV = async () => {
    setExportLoading(true)
    const MAX_LIMIT = 50
    try {
      const totalPages = Math.ceil(totalTx / MAX_LIMIT)
      const mapAllTransactions = []
      for (let i = 1; i <= totalPages; i++) {
        const txData = async (page) => {
          const data = await getTransactions(page, MAX_LIMIT)
          console.log(data)
          return data.results
        }
        mapAllTransactions.push(txData(i))
      }
      const allTransactions = await Promise.all(mapAllTransactions)
      const transformedTransactions = allTransactions.reduce((prev, curr) => {
        return [...prev, ...curr]
      }, [])
      const exportData = formatExportData(transformedTransactions)
      setExportData(exportData)
      setExportLoading(false)
      await csvRef.current.link.click()
    } catch (e) {
      console.error(e)
      setExportError('Exporting CSV file failed.')
      setExportLoading(false)
    }
  }

  const accountsList = [{ username: user.username }, ...user.attachedProfiles]

  const exportCSVFilename = `Pouch_${format(new Date(), 'ddMMMyyyy')}_${activeProfileUsername}.csv`

  return (
    <>
      {exportLoading
        ? <Notification
          mb='sm'
          loading
          title='Exporting CSV file'
          disallowClose
        >
          Please wait while we are preparing your CSV file
        </Notification>
        : null}
      {exportError
        ? <Notification
          mb='sm'
          icon={<IconExclamationMark stroke={1.5} />}
          color='error'
          title={exportError}
          onClose={() => setExportError(null)}
        />
        : null}
      <Card
        title={
          <Group position='apart'>
            <Group spacing='xs' position='right'>
              <DateRange
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                handleApplyDateRange={handleApplyDateRange}
                loading={loading}
              />
              {user.attachedProfiles.length > 1 ? (
                <Group direction='row'>
                  <Text weight={600}>Switch to a Linked Account</Text>
                  <Select
                    placeholder='Username'
                    value={activeProfileUsername}
                    data={accountsList.map(({ username }) => ({ value: username, label: username }))}
                    onChange={(value) => setActiveProfileUsername(value)}
                    disabled={loading || exportLoading}
                  />
                </Group>
              ) : null}
            </Group>
            <Button
              my='xs'
              variant="subtle"
              leftIcon={<DocumentDownload />}
              onClick={handleDownloadCSV}
              disabled={loading}
            >
              Download CSV
            </Button>
          </Group>
        }
      >
        <CSVLink
          ref={csvRef}
          data={exportData}
          headers={headers}
          filename={exportCSVFilename}
          target='_blank'
        />
        <Table
          data={data}
          columns={columns}
          striped
          height={588}
          loading={loading}
          totalPages={totalPages}
          pageNum={pageNum}
          setPageNum={setPageNum}
        />
      </Card>
    </>
  )
}

export default Activity

const useStyles = createStyles((theme) => ({
  addedAmount: {
    color: theme.other.success
  },
  completed: {
    color: theme.other.success
  },
  issued: {
    color: theme.other.warning
  },
  processing: {
    color: theme.other.warning
  },
  failed: {
    color: theme.other.error
  },
  refunded: {
    color: theme.other.error
  },
  cancelled: {
    color: theme.other.error
  }
}))
