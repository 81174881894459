import Layout from "./Layout"
const PrivacyPolicy = () => {
    return (
        <Layout>
<br/>
PRIVACY NOTICE
<br/>
LAST UPDATED 11 SEPTEMBER 2023
<br/>

<br/>
Thank you for choosing to be part of our community at Pouch. This privacy notice explains how Pouch PH Inc., Pouch Philippines, Inc. (formerly Safriton Technologies Corporation), and affiliates (“Pouch”, “we”, “us”, “our”) use and process your information. 
<br/>

<br/>
We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at dataprivacy@pouch.ph.
<br/>

<br/>
This privacy notice describes how we may collect, use, and share your information when you:
<br/>

<br/>
Visit our website at pouch.ph
<br/>

<br/>
Download and use our mobile application — Pouch
<br/>

<br/>
Engage with us in other related ways ― including any sales, marketing, or events
<br/>

<br/>
In this privacy notice, if we refer to:
<br/>

<br/>
"Website," we are referring to any website of ours that references or links to this privacy notice
<br/>

<br/>
"App," we are referring to any application of ours that references or links to this privacy notice, including any listed above
<br/>

<br/>
"Services," we are referring to our Website, App, and other related services, including any sales, marketing, or events
<br/>

<br/>
The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately. Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.
<br/>

<br/>
WHAT SERVICES DO WE OFFER?
<br/>

<br/>
Pouch is registered with the Bangko Sentral ng Pilipinas (“BSP”) as an Operator of Payment Systems (“OPS”), and is not a bank. Pouch provides financial services in partnership with a licensed bank “Netbank”, a licensed Virtual Asset Service Provider “Coex Star”, and several product partners. We proudly offer the following suite of services:
<br/>

<br/>
Local Inter-Bank Transfers: Seamlessly send your PHP Balance to any PESONet and InstaPay member bank or e-wallet;
<br/>

<br/>
Bills Payment & E-Pins: Conveniently pay your bills, utilities, and purchase electronic/gaming pins using Pouch;
<br/>

<br/>
Lightning Services: Experience the future of payments by sending and receiving funds across borders with any lightning-enabled eWallet or merchant. The Lightning Network, a Layer 2 communication and payment protocol, is built on top of Bitcoin, offering a modern way to transact globally;
<br/>

<br/>
Bitcoin On-chain Transactions: Securely conduct Bitcoin on-chain transactions, which are transparently added to the public ledger;
<br/>

<br/>
Remittances via the Lightning Network: Utilize our 'Send Globally' feature to effortlessly send money directly to any US Bank Account.
<br/>

<br/>
WHAT INFORMATION DO WE COLLECT?
<br/>

<br/>
In Short:  We collect personal information that you provide to us. 
<br/>

<br/>
We collect personal information that you voluntarily provide to us when you use or register for Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by accessing our Website, posting messages in our online forums, or entering competitions, contests or giveaways) or otherwise when you contact us.
<br/>

<br/>
Where we refer to: "Website" we are referring to pouch.ph, and any other website of ours that references or links to this privacy notice "App" we are referring to any Pouch app (mobile app or web app) or other application of ours that references or links to this privacy notice, including any listed above "Services" we are referring to our Website, App, and other related services, including any sales, marketing, or events.
<br/>

<br/>
The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:
<br/>

<br/>
Personal Information Provided by You. We collect names; phone numbers; email addresses; usernames; passwords; and other similar information.
<br/>

<br/>
All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
<br/>

<br/>
Information collected through our Services
<br/>

<br/>
We collect information when you utilize our services. This may include transactional information (such as information about payments you make) or other information such as the other party to your transaction. We may also collect location information and other information about the device you are using, for instance, device model, operating system, IP address, app version, and so forth. We (and our service providers) may use tracking technologies, such as cookies and web beacons, to collect information about you and how our Services are used.
<br/>

<br/>
We may request access or permission to certain features from your mobile device, including your mobile device's camera and other features. If you wish to change our access or permissions, you may do so in your device's settings.
<br/>

<br/>
We, our partners, and service providers may also collect other information about you from third-party sources, market research activities,  through an accredited Third-Party platform or channel, and from Pouch’s subsidiaries, affiliates, business partners, and third-party service providers. This may include identity verification and know-your-customer services, as well as compliance screening services. We may also receive information about you from other users or third parties, such as when they engage in transactions with you through our Services or invite you to use our Services. This information may include transaction descriptions that relate to you or your name or username.
<br/>

<br/>
This information is primarily needed to provide our Services, maintain the security and operation of our App, perform troubleshooting, and comply with laws, as well as for our internal analytics and reporting.
<br/>

<br/>
As of the date of publication, our existing affiliates and partners are the following:
<br/>

<br/>
a. Pouch PH Inc. - State of Delaware, United States
<br/>

<br/>
b. Pouch SV, S.A. DE C.V. - Republic of El Salvador
<br/>

<br/>
c. Netbank (a Rural Bank) Inc.
<br/>

<br/>
d. Aba Global Philippines Inc. under the trade names/ of COEX STAR
<br/>

<br/>
e. First Community Cooperative (FICCO)
<br/>

<br/>
We may amend this list from time to time to ensure that it remains consistent with any developments in the way Pouch may use your personal data in delivering its services. We will provide you with any relevant changes through our privacy notices available strategically in our data collection touchpoints.
<br/>

<br/>
HOW DO WE USE YOUR INFORMATION?
<br/>

<br/>
In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
<br/>

<br/>
We use your information for a variety of business purposes, including to provide, maintain, and improve our Services, including to process transactions, identify and  repair errors in our Services, and analyze usage over time. We process your personal information for these purposes in reliance on our legitimate business interests in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
<br/>

<br/>
We use the information we collect or receive:
<br/>

<br/>
To facilitate account creation, authentication, and logon processes. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.
<br/>

<br/>
To post testimonials. We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update or delete your testimonial, please contact us at dataprivacy@pouch.ph and support@pouch.ph, and be sure to include your name, testimonial location, and contact information.
<br/>

<br/>
Request feedback. We may use your information to request feedback and to contact you about your use of our Services.
<br/>

<br/>
To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.
<br/>

<br/>
To manage user accounts. We may use your information for the purposes of managing our Services, and your account and keeping it in working order.
<br/>

<br/>
To send administrative information to you. We may use your personal information to send you product, service, and new feature information and/or information about changes to our terms, conditions, and policies.
<br/>

<br/>
To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).
<br/>

<br/>
To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements, or in connection with our contract.
<br/>

<br/>
To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
<br/>

<br/>
Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.
<br/>

<br/>
Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in our competitions.
<br/>

<br/>
To deliver and facilitate delivery of services to the user. We may use your information to provide you with the requested service.
<br/>

<br/>
To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
<br/>

<br/>
WILL YOUR INFORMATION BE SHARED WITH ANYONE?
<br/>

<br/>
In Short:  We only share information with your consent, to comply with laws, to provide you with Services, to protect your rights, or to fulfill business obligations or our legal and regulatory obligations.
<br/>

<br/>
We may share information about or relating to you:
<br/>

<br/>
With companies, professional advisors, and contractors that access your information to perform services for us, such as companies that assist us with web hosting, data storage, identity verification, fraud prevention, payment processing, financial services, customer service and support, legal and consulting services, customer communications, analytics, and marketing and advertising;
<br/>

<br/>
Depending on your location, we, or our third- party partners may provide your personal information to vendors to help verify your identity and prevent fraud;
<br/>

<br/>
With business partners that provide products or services you request or that jointly offer products and services with us, including in connection with our (or their) legal and regulatory obligations or compliance processes;
<br/>

<br/>
With other parties to transactions, including other people you are sending payments to or receive payment from, merchants when you use the Services to pay for goods and services, and their service providers;
<br/>

<br/>
If we believe that disclosure is in accordance with, or required by, any applicable law or legal process. For example, we may disclose information about users or transactions in connection with tax filings, regulatory requests, court orders, or law enforcement requests;
<br/>

<br/>
If we believe that your actions are inconsistent with our user agreements or policies, that you have violated the law, or that it is necessary to protect our rights or others; and
<br/>

<br/>
With your consent or at your direction.
<br/>

<br/>
LEGAL BASIS
<br/>

<br/>
We may process or share your data that we hold based on the following legal basis:
<br/>

<br/>
Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
<br/>

<br/>
Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
<br/>

<br/>
Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
<br/>

<br/>
Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
<br/>

<br/>
Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
<br/>

<br/>
Business Transfers: We may share or transfer your personal information in connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company upon prior notice, including but not limited to in-App notifications, SMS, email, or registered mail.
<br/>

<br/>
DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
<br/>

<br/>
In Short:  We may use cookies and other tracking technologies to collect and store your information.
<br/>

<br/>
We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
<br/>

<br/>
HOW LONG DO WE KEEP YOUR INFORMATION?
<br/>

<br/>
In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
<br/>

<br/>
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the  period of time in which users have an account with us.
<br/>

<br/>
When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
<br/>

<br/>
We or our third- party partners and service providers may retain certain of your information as needed for our or their legal and regulatory obligations, for instance any know-your-customer information or transactional information.
<br/>

<br/>
HOW DO WE KEEP YOUR INFORMATION SAFE?
<br/>

<br/>
In Short:  We aim to protect your personal information through a system of organizational and technical security measures.
<br/>

<br/>
We prioritize your security and comfort. To ensure this, we have put in place advanced technical and organizational measures to safeguard your personal information. 
<br/>

<br/>
While we strive to offer a secure platform, it is essential to remember that no electronic system is impervious to all potential threats. We are committed to protecting all company data. However, we also encourage you to use our Services within a secure environment. By working together, we can ensure a safer experience. It is imperative to understand that should issues arise due to individual negligence or if one becomes a victim of account manipulation or other fraudulent activities, the responsibility is to be borne by the user concerned.
<br/>

<br/>
In our continued efforts to prioritize your security, all records in our database, which store users' personal and sensitive information, are encrypted using state-of-the-art encryption methods. Access to this database is strictly limited to a select group of individuals with administrator privileges, ensuring that your data remains protected and is handled with the utmost care and discretion.
<br/>

<br/>
DO WE COLLECT INFORMATION FROM MINORS?
<br/>

<br/>
In Short:  We do not knowingly collect data from or market to children under 18 years of age.
<br/>

<br/>
We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at support@pouch.ph.
<br/>

<br/>
WHAT ARE YOUR PRIVACY RIGHTS?
<br/>

<br/>
Under Philippine laws, you have several rights which you can exercise as a Data Subject:
<br/>

<br/>
(1) Right to information: We can inform you about our methods of managing your personal data.
<br/>

<br/>
(2) Right to access: You are entitled to view the personal data we maintain about you.
<br/>

<br/>
(3) Right to object: If we utilize your personal data based on your consent or legitimate interest, you can object.
<br/>

<br/>
(4) Right to erasure or blocking:  You have the authority to request us to stop, withdraw, block, delete, or destroy your personal information.
<br/>

<br/>
(5) Right to damages:  Should there be a breach of your privacy rights that caused you harm and damage, you can claim for compensation. 
<br/>

<br/>
(6) Right to file a complaint:  Should there be a violation of your privacy rights, you can file a complaint with the National Privacy Commission.
<br/>

<br/>
(7) Right to rectify: You can challenge, dispute, and correct inaccuracies in your personal data.
<br/>

<br/>
(8) Right to information portability:  You can request us to securely transfer, move, copy your personal information from one company to another.
<br/>

<br/>
For more information about your privacy rights, you can visit the website of the National Privacy Commission.
<br/>

<br/>
If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
<br/>

<br/>
https://ec.europa.eu/justice/data-protection/bodies/authorities/index_e
<br/>

<br/>
N.htm.
<br/>

<br/>
If you are a resident in Switzerland, the contact details for the data protection authorities are available here: 
<br/>

<br/>
https://www.edoeb.admin.ch/edoeb/en/home.html.
<br/>

<br/>
If you have questions or comments about your privacy rights, you may email us at support@pouch.ph. 
<br/>

<br/>
Account Information: If you would at any time like to review or change the information in your account or terminate your account, you can contact us using the contact information provided.
<br/>

<br/>
Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we and our third-party partners may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
<br/>

<br/>
Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
<br/>

<br/>
Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example, to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. 
<br/>

<br/>
To otherwise opt-out, you may contact us at: 
<br/>

<br/>
	Data Privacy Office: dataprivacy@pouch.ph
<br/>

<br/>
Customer Support Email: support@pouch.ph
<br/>

<br/>
CONTROLS FOR DO-NOT-TRACK FEATURES
<br/>

<br/>
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. 
<br/>

<br/>
DO WE MAKE UPDATES TO THIS NOTICE?
<br/>

<br/>
In Short:  Yes, we will update this notice as necessary to stay compliant with relevant laws.
<br/>

<br/>
We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
<br/>

<br/>
HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
<br/>

<br/>
Based on the applicable laws of your country, you have the right to request access to the personal information we collect from you. You can access your account settings, update your preferences, modify inaccurate data, or delete your account.
<br/>

<br/>
You have a right to file a complaint with the National Privacy Commission should you feel that your personal information has been misused, maliciously disclosed, or improperly disposed, or that any of your data privacy rights have been violated. Consequently, you have a right to claim damages in case of inaccurate, incomplete, outdated, false, unlawfully obtained, or unauthorized use of personal information.
<br/>

<br/>
To request to review, update, or delete your personal information, please submit an email request to dataprivacy@pouch.ph and support@pouch.ph.
<br/>

<br/>
____________________________________________________________________________
<br/>

<br/>
Version: 1.0
<br/>

<br/>
This Policy, and any updates, amendments or supplements thereto, is available at Pouch’s website at  (https://www.pouch.ph/privacy-policy/)
<br/>
        </Layout>
    )
}
export default PrivacyPolicy
    