export default {
  COLORS: {
    ULTRAVIOLET: '#6922FF',
    NIGHTTIME: '#160055',
    EMERALD: '#1CD787',
    BITTERSWEET: '#FF6B6B',
    BITCOIN_ORANGE: '#F7931A',
    LIGHTNING_YELLOW: '#FFFF55',
    LIGHT_GRAY: '#E5E5E5',
    MED_GRAY: '#929497',
    DARK_GRAY: '#393E41'
  },
  CURRENCY_DICTIONARY: {
    BTC: { label: 'Bitcoin', value: 'BTC' },
    PHP: { label: 'Philippine Peso', value: 'PHP' },
    USD: { label: 'United States Dollar', value: 'USD' },
    CAD: { label: 'Canadian Dollar', value: 'CAD' }
  }
}
