import React, { useState } from 'react'
import {
  useMantineTheme,
  Grid,
  Box,
  Text,
  Center,
  Paper,
  TextInput,
  Switch,
  FileInput,
  Image,
  Group,
  ActionIcon,
} from '@mantine/core'
import 'react-responsive-carousel/lib/styles/carousel.css'
import { Carousel } from 'react-responsive-carousel'
import { IconUpload, IconHelp } from '@tabler/icons'
import { Card, ListItem, Select } from 'components'
import BusinessProfile from './BusinessProfile'
import Logo from './Logo'
import instapayLogo from 'assets/images/instapay.png'

const INITIAL_SLIDE = 0

const SlideContent = ({ active, children }) => (
  <Card
    sx={{
      filter: active ? 'none' : 'brightness(90%)',
      pointerEvents: 'none',
      userSelect: 'none',
      textAlign: 'left',
      position: 'relative',
      maxWidth: 450,
      margin: '0 auto'
    }}
  >
    <Box sx={{ height: '100%' }}>
      {children}
    </Box>
    <Logo />
  </Card>
)

const StoreProfile = ({ user }) => {
  const theme = useMantineTheme()
  const [slideNum, setSlideNum] = useState(INITIAL_SLIDE)
  // Main Screen
  const [businessName, setBusinessName] = useState(user?.name)
  // Bank Instructions Page
  const [bankName, setBankName] = useState('')
  const [accountName, setAccountName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')

  const handleSlideChange = (value) => {
    setSlideNum(value)
  }

  const slidePanels = [
    {
      title: 'Main Screen',
      description: 'What users see when first entering your Pouch Checkout Page.',
      children: (
        <Box>
          <TextInput
            mt='lg'
            size='lg'
            label='Business Name'
            placeholder='Input Business Name'
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          <FileInput
            mt='lg'
            size='lg'
            label='Business Logo'
            placeholder='Upload Business Logo'
            icon={<IconUpload />}
          />
          <Box mt='lg'>
            <Text weight={600}>Advanced Settings</Text>
            <ListItem
              name='Hide GCash'
              rightComponent={<Switch size="md" />}
            />
          </Box>
        </Box>
      )
    },
    {
      title: 'Bank Instructions Page',
      description: 'Where the users can follow the InstaPay bank transfer instructions.',
      children: (
        <Box>
          <TextInput
            mt='lg'
            size='lg'
            label='Bank Name'
            placeholder='Input Bank Name'
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />
          <TextInput
            mt='lg'
            size='lg'
            label='Account Name'
            placeholder='Input Account Name'
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
          <TextInput
            mt='lg'
            size='lg'
            label='Account Number'
            placeholder='Input Account Number'
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </Box>
      )
    },
    {
      title: 'Bitcoin / Lightning Page',
      description: 'Where the users can scan a custom QR code to pay using any Bitcoin or Lightning wallet.',
      children: (
        <></>
      )
    }
  ]

  return (
    <Grid>
      <Grid.Col sm={8}>
        <Paper
          radius='lg'
          p='lg'
          sx={{
            height: '100%',
            boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.05)',
            backgroundColor: '#CCC'
          }}
        >
          <Group mb='xl' position='apart'>
            <Text weight={700}>Preview</Text>
            <ActionIcon variant='transparent'>
              <IconHelp />
            </ActionIcon>
          </Group>
          <Carousel
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            selectedItem={slideNum}
            onChange={handleSlideChange}
          >
            <SlideContent active={slideNum === 0}>
              <BusinessProfile name={businessName} />
              <TextInput
                mt='lg'
                size='lg'
                label='Amount'
                placeholder='Enter Amount'
              />
              <Text mt='lg' weight={500}>Payment Method</Text>
            </SlideContent>
            <SlideContent active={slideNum === 1}>
              <BusinessProfile name={businessName} />
              <Center>
                <Image src={instapayLogo} width={135} />
              </Center>
              <Text weight={700}>Instructions</Text>
              <Box m='lg'>
                <Text>1. Open your bank or eWallet App</Text>
                <Text>2. Select Bank Transfer (InstaPay)</Text>
              </Box>
              <Grid>
                <Grid.Col span={3}>
                  <Text>Bank:</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Text weight={700}>{bankName}</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>Name:</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Text weight={700}>{accountName}</Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text>Account:</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Text weight={700}>{accountNumber}</Text>
                </Grid.Col>
              </Grid>
            </SlideContent>
            <SlideContent active={slideNum === 2}>
              <BusinessProfile name={businessName} />
              <Box my='sm' sx={{
                width: '100%',
                height: 300,
                backgroundColor: theme.other[theme.colorScheme].borderColor,
              }}></Box>
              <Box my='md' sx={{
                borderRadius: theme.radius.md,
                width: '100%',
                height: 60,
                backgroundColor: theme.other[theme.colorScheme].borderColor,
              }}></Box>
            </SlideContent>
          </Carousel>
        </Paper>
      </Grid.Col>
      <Grid.Col sm={4}>
        <Card>
          <Select
            size='lg'
            label='Select Screen'
            value={slideNum}
            data={[
              { value: 0, label: slidePanels[0].title },
              { value: 1, label: slidePanels[1].title },
              { value: 2, label: slidePanels[2].title },
            ]}
            onChange={handleSlideChange}
            sx={{
              input: {
                fontWeight: 700
              }
            }}
          />
          <Text mt='sm'>{slidePanels[slideNum]?.description}</Text>
          {slidePanels[slideNum]?.children}
        </Card>
      </Grid.Col>
    </Grid >
  )
}

export default StoreProfile