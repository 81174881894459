import React from 'react'
import { format } from 'date-fns'
import {
  useMantineTheme,
  Title,
  Text,
  Group,
  Alert,
  Container,
  Stack,
  Box
} from '@mantine/core'
import { IconCircleCheck, IconAlertTriangle } from '@tabler/icons'
import { Button, Card } from 'components'
import formatBalance from 'helpers/format-balance'

const Step3 = ({ response, totalAmount, setTotalAmount, totalUsers, activeProfileUsername, primaryCurrency, setRows, setStep, refreshActiveProfile }) => {
  const theme = useMantineTheme()

  const handleNewPayment = () => {
    setRows([])
    setTotalAmount('0')
    setStep(1)
    refreshActiveProfile()
  }

  const renderSuccess = () => {
    const details = [
      { name: 'Transaction ID', value: response?.data?._id },
      { name: 'Date', value: format(Date.now(), 'MMMM dd, yyyy') },
      { name: 'Account', value: activeProfileUsername }
    ]
    return (
      <Box>
        <Stack align='center' my="xl">
          <IconCircleCheck size={100} color={theme.other[theme.colorScheme].success} />
          <Title order={3}>Batch Payment Successful</Title>
          <Text weight={500} size="xl">Total of {formatBalance(totalAmount, primaryCurrency)} sent to {totalUsers} users</Text>
        </Stack>
        <Box my="xl">
          {details.map(({ name, value }) => (
            <Group key={name} position="apart">
              <Text weight={600}>{name}</Text>
              <Text>{value}</Text>
            </Group>
          ))}
        </Box>
        <Button mt="xl" radius="max" fullWidth size='lg' color='primary' onClick={handleNewPayment}>
          New Batch Payment
        </Button>
      </Box>
    )
  }

  const renderError = () => {
    const details = [
      { name: 'Date', value: format(Date.now(), 'MMMM dd, yyyy') },
      { name: 'Account', value: activeProfileUsername }
    ]
    return (
      <Box>
        <Stack align='center' my="xl">
          <IconAlertTriangle size={100} color={theme.other[theme.colorScheme].error} />
          <Title order={3}>Batch Payment Failed</Title>
          <Text size="lg">An error has occurred while processing batch payment</Text>
          <Title order={6} underline>Don’t worry your account was not deducted</Title>
          <Alert title="Error" color="red">{response?.data}</Alert>
        </Stack>
        <Box my="xl">
          {details.map(({ name, value }) => (
            <Group key={name} position="apart">
              <Text weight={600}>{name}</Text>
              <Text>{value}</Text>
            </Group>
          ))}
        </Box>
        <Text mt="xl" mb="md" align='center'>Create a new batch payment to try again.</Text>
        <Button radius="max" fullWidth size='lg' color='primary' onClick={handleNewPayment}>
          New Batch Payment
        </Button>
      </Box>
    )
  }

  return (
    <Container size="sm">
      <Card>
        {response.status === 200 ? renderSuccess() : renderError()}
      </Card>
    </Container>
  )
}

export default Step3
