import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import {
  useMantineTheme,
  createStyles,
  TextInput,
  Space,
  Title,
  Button,
  Container,
  Text,
  Image,
  useMantineColorScheme,
  Group,
  Center
} from '@mantine/core'
import pouchLogo from 'assets/images/logo.svg'
import pouchLogoWhite from 'assets/images/logo-white.svg'

const Signin = ({ setUser }) => {
  const { classes } = useStyles()
  const history = useHistory()
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSignIn = e => {
    e.preventDefault()
    setLoading(true)
    const data = { username, password }
    axios.post('/api/v0/auth', data)
      .then((res) => {
        setUser(res.data.user)
        setError(null)
        history.replace('/pay/dashboard')
      })
      .catch((err) => {
        setLoading(false)
        if ([400, 401].includes(err.response.status)) {
          setError('Invalid username or password.')
        } else {
          setError(err.response?.data?.message)
        }
      })
  }

  return (
    <Container className={classes.wrapper}>
      <Center>
        <Image mb='sm' src={colorScheme === 'light' ? pouchLogo : pouchLogoWhite} width={60} />
      </Center>
      <Title order={2} sx={{ textAlign: 'center', margin: '48px' }}>Sign In</Title>
      <Space h='xl' />
      <form onSubmit={handleSignIn}>
        <TextInput size='lg' value={username} label='Username' placeholder='Username' onChange={e => setUsername(e.target.value)} type='text' />
        <Space h='md' />
        <TextInput size='lg' value={password} label='Password' placeholder='Password' onChange={e => setPassword(e.target.value)} type='password' />
        {error && <Text mt='lg' size='lg' className={classes.error}>{error}</Text>}
        <Button mt='xl' size='lg' radius='md' fullWidth type='submit' loading={loading} disabled={loading}>Sign In</Button>
      </form>
    </Container>
  )
}

export default Signin

const useStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: 556
  },
  error: {
    color: theme.other.error,
    textAlign: 'center'
  }
}))
