import React from 'react'
import { Group, Text, UnstyledButton, ThemeIcon } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons'

const UserDropdownMenu = ({ user, onClick }) => {
  return (
    <UnstyledButton onClick={onClick}>
      <Group>
        <Text size='xl' weight={700}>{user?.username}</Text>
        <ThemeIcon color='transparent'>
          <IconChevronDown />
        </ThemeIcon>
      </Group>
    </UnstyledButton>
  )
}

export default UserDropdownMenu
