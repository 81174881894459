import Layout from "./Layout"
const PrivacyPolicy = () => {
    return (
        <Layout>
            <h1>Ambassador Program</h1>
            The Pouch Ambassador Program is a private referral program that was designed to reward our most loyal &amp; passionate users for spreading the word about Pouch. Rewards include limited edition gift items, special discounts and promotions, and cash bonuses. If you are interested in joining the program, please send an inquiry to <a href="mailto:support@pouch.ph">support@pouch.ph</a>.
        </Layout>
    )
}
export default PrivacyPolicy
    