import React from "react";
import {
  useMantineTheme,
  Burger,
  MediaQuery,
  Text,
  Box,
  Group,
  Divider,
  Breadcrumbs
} from "@mantine/core";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { IconChevronRight } from '@tabler/icons'
import NotificationDropdown from "../NotificationDropdown";
import UserMenu from "./UserMenu";

const NAVBAR_WIDTH = 262
const HEADER_HEIGHT = 97

const CustomHeader = ({ breadcrumbs, user, opened, setOpened, handleDrawer }) => {
  const theme = useMantineTheme();
  const navbarHidden = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const { width } = useViewportSize();
  const matches = useMediaQuery("(min-width: 386px)");

  const renderBreadcrumbs = () => {
    return breadcrumbs.map((name, i) => {
      return (
        <Text key={i} size="xl" weight={700}>{name}</Text>
      )
    })
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.other[theme.colorScheme].cardBgColor,
        top: 0,
        zIndex: 999,
        width: navbarHidden ? '100%' : width - NAVBAR_WIDTH,
        height: HEADER_HEIGHT,
        position: 'fixed',
        borderBottom: `1px solid ${theme.other[theme.colorScheme].outline}`,
      }}>
      {user ? (
        <>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Box
              p='xl'
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Breadcrumbs separator={<IconChevronRight strokeWidth={3} size={20} color={theme.other[theme.colorScheme].text} />}>
                {renderBreadcrumbs()}
              </Breadcrumbs>
              <Group spacing="lg">
                {matches ? (
                  <>
                    <NotificationDropdown />
                    <Divider orientation="vertical" />
                  </>
                ) : null}
                <UserMenu user={user} onClick={handleDrawer} />
              </Group>
            </Box>
          </MediaQuery>
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
              <Group>
                {matches ? (
                  <>
                    <NotificationDropdown />
                    <Divider orientation="vertical" />
                  </>
                ) : null}
                <UserMenu user={user} onClick={handleDrawer} />
              </Group>
            </Box>
          </MediaQuery>
        </>
      ) : null}
    </Box>
  );
};

export default CustomHeader
