import { isSafeNumber } from './data-type-helpers'
import sb from 'satoshi-bitcoin'
import roundToPlaces from './round-to-places'

const convert = (amount, from, to, exchangeRates, forexMultiplier = 1) => {
  const C = { BTC: 'BTC', MILLISATOSHIS: 'MILLISATOSHIS' }
  if (from === to) return amount // early exit without converting

  // don't take an exchange rate when converting between different denominations of the same currency
  if (['BTC', 'MILLISATOSHIS', 'SAT'].includes(from) && ['BTC', 'MILLISATOSHIS', 'SAT'].includes(to)) {
    forexMultiplier = 1
  }

  let amountInSats
  switch (from) {
    case C.BTC:
      amountInSats = Number(amount)
      break
    case C.MILLISATOSHIS:
      amountInSats = amount / 1000
      break
    default:
      amountInSats = sb.toSatoshi(amount / exchangeRates[from])
  }

  amountInSats = parseInt(amountInSats)

  let convertedAmount
  switch (to) {
    case C.BTC:
      convertedAmount = amountInSats * forexMultiplier
      break
    case C.MILLISATOSHIS:
      convertedAmount = parseInt(amountInSats * forexMultiplier) * 1000
      break
    default:
      convertedAmount = roundToPlaces(sb.toBitcoin(amountInSats) * exchangeRates[to] * forexMultiplier, 8)
  }
  if (isSafeNumber(convertedAmount) && isSafeNumber(amount)) {
    return convertedAmount
  } else {
    throw new Error('BigInt not allowed.')
  }
}

export default convert
