import { useState } from 'react'
import axios from 'axios'
import { format } from 'date-fns'
import {
  Text,
  Group,
  Loader,
  Stack,
  Title,
  Box,
  Container
} from '@mantine/core'
import { IconArrowNarrowLeft } from '@tabler/icons'
import { Button, Card } from 'components'
import formatBalance from 'helpers/format-balance'

const Step2 = ({ rows, totalAmount, setStep, activeProfileUsername, primaryCurrency, setResponse }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleBack = () => setStep(1)

  const handleConfirm = () => {
    setIsLoading(true)
    const data = rows // [{ username: 'string', amount: 100, currency: 'PHP' }]
    axios.post(`/api/v0/transaction/batch-payment?activeProfileUsername=${activeProfileUsername}`, data)
      .then(res => {
        setResponse(res)
        setIsLoading(false)
        setStep(3)
      })
      .catch(err => {
        setResponse(err.response)
        setIsLoading(false)
        setStep(3)
      })
  }

  const renderConfirmation = () => {
    const details = [
      { name: 'Date', value: format(Date.now(), 'MMMM dd, yyyy') },
      { name: 'Account', value: activeProfileUsername }
    ]
    return (
      <Box>
        <Stack align='center'>
          <Title order={3}>Confirmation</Title>
          <Text size="lg">You are about to send a total of</Text>
          <Title order={2}>{formatBalance(totalAmount, primaryCurrency)}</Title>
          <Text size="lg">to</Text>
          <Title order={4}>{rows.length} users</Title>
        </Stack>
        <Box py="xl">
          {details.map(({ name, value }) => (
            <Group key={name} position="apart">
              <Text weight={600}>{name}</Text>
              <Text>{value}</Text>
            </Group>
          ))}
        </Box>
        <Button
          size='lg'
          fullWidth
          onClick={handleConfirm}
          radius="max"
        >
          Confirm
        </Button>
      </Box>
    )
  }

  const renderProcessing = () => {
    const details = [
      { name: 'Date', value: format(Date.now(), 'MMMM dd, yyyy') },
      { name: 'Account', value: activeProfileUsername },
      { name: 'Users', value: rows.length },
      { name: 'Total Amount', value: formatBalance(totalAmount, primaryCurrency) }
    ]
    return (
      <Box>
        <Stack align='center'>
          <Loader size="xl" my="xl" />
          <Title order={3}>Processing Batch Payment</Title>
          <Text size="lg">Please wait while we process your payment</Text>
        </Stack>
        <Box py="xl">
          {details.map(({ name, value }) => (
            <Group key={name} position="apart">
              <Text weight={600}>{name}</Text>
              <Text>{value}</Text>
            </Group>
          ))}
        </Box>
      </Box>
    )
  }

  return (
    <Container size="xs">
      {isLoading
        ? null
        : <Button
          onClick={handleBack}
          leftIcon={<IconArrowNarrowLeft />}
          variant='transparent'
        >
          Back
        </Button>
      }
      <Card>
        {isLoading ? renderProcessing() : renderConfirmation()}
      </Card>
    </Container>
  )
}

export default Step2
