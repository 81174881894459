import Layout from "./Layout"
const PrivacyPolicy = () => {
    return (
        <Layout>
            <h1>Limits</h1>
            Withdrawals are limited to PHP 50,000 per day.
            <br/><br/>
            To increase your limits, please send an email request to <a href="mailto:support@pouch.ph">support@pouch.ph</a>.
        </Layout>
    )
}
export default PrivacyPolicy
    