import React from 'react'
import { useMantineTheme, Text, Center, Stack, } from '@mantine/core'
import { Gallery } from 'iconsax-react'

const BusinessProfile = ({ name }) => {
  const theme = useMantineTheme()
  return (
    <Stack align="center">
      <Center sx={{
        borderRadius: theme.radius.max,
        width: 80,
        height: 80,
        backgroundColor: theme.other[theme.colorScheme].borderColor,
      }}>
        <Gallery color={theme.other[theme.colorScheme].secondary} />
      </Center>
      <Text weight={700} sx={{ color: theme.other[theme.colorScheme].secondary }}>
        {name}
      </Text>
    </Stack>
  )
}

export default BusinessProfile
