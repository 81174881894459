import { useEffect, useRef, useState } from 'react'
import Layout from './Layout'
import axios from 'axios'

const Online = () => <span style={{fontWeight: "bold", color: "#5A5"}}>Online.</span>
const TemporarilyUnavailable = () => <span style={{fontWeight: "bold", color: "#BB4"}}>Temporarily Unavailable.</span>
const ComingSoon = () => <span style={{fontWeight: "bold", color: "#BB4"}}>Coming Soon.</span>

// const FacebookChatPlugin = () => {
//     const MessengerRef = useRef()
//     useEffect(() => {
//         MessengerRef.current.setAttribute("page_id", "111008054548490");
//         MessengerRef.current.setAttribute("attribution", "biz_inbox");
//         window.fbAsyncInit = function () {
//             FB.init({
//                 xfbml: true,
//                 version: 'v16.0'
//             });
//         };
//         (function (d, s, id) {
//             var js, fjs = d.getElementsByTagName(s)[0];
//             if (d.getElementById(id)) return;
//             js = d.createElement(s); js.id = id;
//             js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
//             fjs.parentNode.insertBefore(js, fjs);
//         }(document, 'script', 'facebook-jssdk'));
    
//     }, [])
//     return (
//         <>
//             <div id="fb-root"></div>
//             <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat"></div>
//         </>
//     )
// }

const Home = () => {
    // const [rates, setRates] = useState()
    // useEffect(async () => {
    //     const res = await axios.get('/api/v0/exchange-rates')
    //     setRates(res.data.rates)
    // }, [])
    return (
        <Layout>
            <a href="https://pouch.ph/download">
                <div style={{textAlign: 'center'}}>
                    <div style={{display: 'inline-block', backgroundColor: '#EDF', padding: '20px 40px', borderRadius: '4px'}}>
                        <b style={{color: 'black'}}>Download the App: <br/>
                        <a href="https://play.google.com/store/apps/details?id=pouch.ph">Google Play</a> &middot; <a href="https://apps.apple.com/us/app/pouch-borderless-payments/id1584404678">App Store</a></b>
                    </div>
                </div>
            </a>
            {/* <FacebookChatPlugin/> */}
            {/* <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                1 BTC = ₱{rates?.PHP?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
            <div style={{textAlign: 'center', fontSize: '10px'}}>Fix the money, fix the world.</div> */}
            <div style={{marginBottom: '24px'}} />
            <div style={{margin: "0 auto", maxWidth: "480px", textAlign: "center"}}>
                <div style={{fontSize: "48px"}}>🇵🇭⚡</div>
                <h3>Philippines, Meet the Lightning Network.</h3>
                <div>Pouch.ph is a BSP approved Operator of Payment Systems OPSCOR-2023-0025 with a presence in Metro Manila, Cebu City, Boracay, Cagayan De Oro City, and across the Philippines.</div>
                <br/>
                <div>We are the first payment system to connect Philippine Pesos with Bitcoin&apos;s Lightning Network, the leading payment protocol enabling instant, global settlement. Our local partners include a BSP-licensed bank &quot;Netbank, Inc.&quot; &amp; a BSP-licensed Virtual Asset Service Provider &quot;Coexstar&quot;.</div>
                <br/><br/>

                <h3>Pouch.ph Services</h3>
                <div>Pouch will be undergoing system upgrades between December 1st and January 15th as a part of the Netbank upgrade. Some services may be intermittently disrupted.</div>
                <br/><br/>
                <div style={{textAlign: 'left'}}>
                    <div><b>Service Status:</b></div>
                    <br/>


                    <div>Peso Transfers</div>
                    <div>Outgoing Bank Transfers &middot; <Online/></div>
                    <div>Incoming Bank Deposits &middot; <Online/></div>
                    <div>Cash Deposit &middot; <Online/></div>
                    <br/>

                    <div>Partners</div>
                    <div>Send Globally via Strike, CoinCorner, NeutronPay &middot; <Online/></div>
                    <div>Pay Bills &amp; Buy Load &middot; <Online/></div>
                    <div>CoopPay &middot; <Online/></div>
                    <br/>

                    <div>Bitcoin Transfers</div>
                    <div>Receive BTC Lightning &middot; <Online/></div>
                    <div>Send BTC Lightning &middot; <Online/></div>
                    <div>Receive BTC On-chain &middot; <Online/></div>
                    <div>Send BTC On-chain &middot; <Online/></div>
                    <div>Universal Money Addresses &middot; (Receive-only) <Online/></div>
                    <div>Lightning Addresses &amp; LNURL &middot; <Online/></div>
                    <div>Send Globally to US Banks &middot; <TemporarilyUnavailable/></div>
                    <div>Recurring Purchases &amp; Wallet Linking &middot; <Online/></div>
                    <br/>

                    <div>New Features</div>
                    <div>PHP Cash Pick-Up &middot; <ComingSoon/></div>
                    <div>Netbank Savings Accounts &middot; <ComingSoon/></div>
                    <div>BTC Balances &middot; <ComingSoon/></div>
                    <div>Buy & Sell Bitcoin &middot; <ComingSoon/></div>
                    <div>Debit Cards &middot; <ComingSoon/></div>
                    <br/>

                    <div>Other</div>
                    <div>Signup & KYC &middot; <Online/></div>
                </div>
            </div>
        </Layout>
    )
}

export default Home
