import { useMantineTheme, Box, Group, Text } from '@mantine/core'

const Cell = ({ children, sx }) => {
  const theme = useMantineTheme()
  return (
    <Box
      p='xs'
      sx={{
        borderStyle: 'solid',
        borderColor: theme.other[theme.colorScheme].text,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 0,
        ...sx
      }}>
      <Text size="md">{children}</Text>
    </Box>
  )
}

const Instructions = () => {
  const theme = useMantineTheme()
  return (
    <Box>
      <Text weight={700} pb='md'>Instructions</Text>
      <Text>Copy rows from spreadsheet (Excel or Google Sheets) with the following format:</Text>
      <Group spacing={0} py='sm'>
        <Cell sx={{ borderLeftWidth: 1 }}>username</Cell>
        <Cell>amount</Cell>
        <Cell>currency</Cell>
      </Group>
      <Text size="sm" italic weight={700}>e.g.</Text>
      <Group spacing={0} pb='md'>
        <Cell sx={{ borderLeftWidth: 1 }}>juanandonly</Cell>
        <Cell>10,000.00</Cell>
        <Cell>PHP</Cell>
      </Group>
      <Group spacing={0}>
        <Text size="sm" weight={700}>Note:</Text>
        <Text size="sm">The currency value is optional. Leaving it blank will use your default currency.</Text>
      </Group>
    </Box>
  )
}

export default Instructions
