import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom'
import { PayAuthLayout, PayLayout } from 'components'
import Dashboard from './Dashboard'
import Account from './Dashboard/Account'
import Activity from './Activity'
import StoreProfile from './StoreProfile'
import Links from './Links'
import BatchPayment from './BatchPayment'

import AllActivity from './AllActivity'

import Signin from './Signin'
import Signup from './Signup'
import VerifyContactInfo from './VerifyContactInfo'

const Pay = () => {
  const history = useHistory()
  const [user, setUser] = useState(null)
  const [userLoading, setUserLoading] = useState(true)

  const getUser = async () => {
    await axios.get('/api/v0/user')
      .then((res) => {
        setUser(res.data.user)
        setUserLoading(false)
      }).catch(() => {
        setUserLoading(false)
        history.replace('/pay/signup')
      })
  }

  useEffect(() => { getUser() }, [])
  useEffect(() => {
    if (!userLoading && !user) history.replace('/pay/signup')
  }, [userLoading, user])

  if (userLoading) {
    return (
      <div style={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <div className="loader" />
      </div>
    )
  }
  return (
    <Router>
      <Route
        path="/pay"
        render={({ match: { url } }) => (
          <Switch>
            {!user && <Route exact path={`${url}/signin`}><PayAuthLayout><Signin setUser={setUser} /></PayAuthLayout></Route>}
            {!user && <Route exact path={`${url}/signup`}><PayAuthLayout><Signup setUser={setUser} /></PayAuthLayout></Route>}
            {user && <Route exact path={`${url}/verify`}><PayAuthLayout setUser={setUser}><VerifyContactInfo user={user} /></PayAuthLayout></Route>}
            {user && !user.verified.email && !user.verified.phone && <Redirect to={`${url}/verify`} />}
            {!user && <Redirect to={`${url}/signup`} />}
            {user && (
              <PayLayout {...{ user, setUser }}>
                <Route exact path={`${url}/dashboard`}><Dashboard user={user} /></Route>
                <Route exact path={`${url}/dashboard/account`}><Account user={user} /></Route>
                <Route exact path={`${url}/activity`}><Activity user={user} /></Route>
                <Route exact path={`${url}/store-profile`}><StoreProfile user={user} /></Route>
                <Route exact path={`${url}/links`}><Links user={user} /></Route>
                {user && <Route exact path={`${url}/batch-payment`}><BatchPayment user={user} /></Route>}
                {['admin', 'engineer', 'manager', 'support'].includes(user.role) && (
                  <>
                    <Route exact path={`${url}/all-activity`}><AllActivity user={user} /></Route>
                  </>
                )}
                <Redirect exact to={`${url}/dashboard`} />
              </PayLayout>
            )}
          </Switch>
        )
        }
      />
    </Router >
  )
}

export default Pay
