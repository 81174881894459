import { useState } from 'react'
import {
  Text,
  Group,
  Grid,
  Divider,
  TextInput,
  Avatar,
  Box,
} from '@mantine/core'
import { Button, Card } from 'components'

const Account = ({ user }) => {
  const [fullName, setFullName] = useState(user?.name)
  const [username, setUsername] = useState(user?.username)
  const [email, setEmail] = useState(user?.email)
  const [mobileNumber, setMobileNumber] = useState(user?.phone)

  const handleFullName = (e) => {
    const { value } = e.target
    setFullName(value)
  }

  const handleUsername = (e) => {
    const { value } = e.target
    setUsername(value)
  }

  const handleEmail = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleMobileNumber = (e) => {
    const { value } = e.target
    setMobileNumber(value)
  }

  return (
    <Card title='Account'>
      <Grid align="center">
        <Grid.Col sm={3}>
          <Text>Account Picture</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <Group>
            <Avatar radius='max' size='xl' />
            <Box>
              <Text mb='sm' weight={700}>image_file_name.png</Text>
              <Group>
                <Button size='xs' compact variant='transparent'>Remove</Button>
                <Divider orientation="vertical" />
                <Button size='xs' compact variant='transparent'>Upload</Button>
              </Group>
            </Box>
          </Group>
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Full Name</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <TextInput
            variant="filled"
            placeholder='Enter full name'
            value={fullName}
            onChange={handleFullName}
            sx={{ flex: 1 }}
          />
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Username</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <TextInput
            variant="filled"
            placeholder='Enter username'
            value={username}
            onChange={handleUsername}
            sx={{ flex: 1 }}
          />
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Email</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <TextInput
            variant="filled"
            placeholder='Enter email'
            value={email}
            onChange={handleEmail}
            sx={{ flex: 1 }}
          />
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Mobile Number</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <TextInput
            variant="filled"
            placeholder='Enter mobile number'
            value={mobileNumber}
            onChange={handleMobileNumber}
            sx={{ flex: 1 }}
          />
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Password</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <Button variant='subtle'>Change Password</Button>
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Account Status</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <Text>LVL</Text>
        </Grid.Col>
        <Grid.Col sm={3}>
          <Text>Delete Account</Text>
        </Grid.Col>
        <Grid.Col sm={9}>
          <Button variant='subtle' color='red'>Request Account Deletion</Button>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default Account